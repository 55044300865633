import st from './navigationBar.module.scss'
import { NavLink } from 'react-router-dom'

function NavigationBar(props) {
  return (
    <div className={st.navigation}>
      {/* <div className={st.navigation_wrapper}>
        <NavLink to="/fantasygames/baseball" exact={true} activeClassName={st.selected} className={st.navigation_link}>
          MegaSports Baseball
        </NavLink>
      </div> */}
      <div className={st.navigation_wrapper}>
      <NavLink to="/fantasygames/football" exact={true} activeClassName={st.selected} className={st.navigation_link}>
        MegaSports Football
      </NavLink>
      </div>
      {/*<div className={st.navigation_wrapper}>
        <NavLink to="/fantasygames/mypicks" activeClassName={st.selected} className={st.navigation_link}>
          My Picks
        </NavLink>
      </div> */}
      {/* <div className={st.navigation_wrapper}>
        <span className={st.navigation_link}>Rules and Scoring</span>
      </div> */}
    </div>
  )
}

export default NavigationBar
