import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CustomInput from '../../UI/customInput/customInput';
import st from './signUp.module.scss';
import ButtonSubmit from '../../UI/buttons/buttonSubmit/buttonSubmit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { addCurrntUser } from '../../../store/reduser';
import CustomSelect from '../../UI/customSelect/customSelect'
import Logo from '../../UI/buttons/logo/logo'


export const SignUp = () => {

  let [birthYear, setBirthYear] = useState("");

  const dispatch = useDispatch();

  // const [yearError, setYearError] = useState(null);

  const ref = useRef("");

  const history = useHistory();

  const [loginError, setLoginError] = useState("");

  const [passwordShown, setPasswordShown] = useState(false);

  let date = new Date().getFullYear() - 21;

  const schema = yup.object().shape({
    full_name: yup.string()
      .required('Full name is required field'),
    user_name: yup.string()
      .required('User name is required field'),
    email: yup.string()
      .required('Email is required field')
      .email('Email is invalid'),
    password: yup.string()
      .required('Password is required field')
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, "Password may contain one capital later and one number")
      .min(8, 'Password must be at least 8 characters'),
    zip_code: yup.string()
      .required('Zipcode is requered field')
      .test('ziplen', 'Must be exactly 5 characters', val => val.length === 5)
      .matches(/^[0-9]{5}$/,"Use only digits"),
    birth_year: yup.string()
      .required('Year of birth is requered field')
      // .test('yearlen', 'Must be exactly 4 characters', val => {
      //   return val.length == 4
      // })
      // .test('adult', 'You must be 21 years old', val => (new Date().getFullYear() - val >= 21))
      // .matches(/^[0-9]{4}$/,"Use only digits"),
  });

  const {register, handleSubmit, 
    formState:{ 
      errors
   }} = useForm({resolver: yupResolver(schema)});
   
  const sendForm = (data) => {

    // validateYear(ref.current.value)

    const body = {
      "email": data.email,
      "zipcode": data.zip_code,
      "password": data.password,
      "full_name": data.full_name,
      "user_name": data.user_name, 
      "birth_year": data.birth_year
    };

    return fetch("https://megasports01.herokuapp.com/project/registration/", {
      method: 'POST',
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    }).then( response => {
      return response.json()
    })
  };

  const options = Array.from({ length: date - 1920 }, (val, i) => {
    let itemDate = date + (i * -1)
    return {
      name: itemDate.toString(),
      value: itemDate.toString()
    }
  })

  const changeYearOfBirth = (field, value) => {
    register('birth_year', { value: value})
    setBirthYear(value)
  }

  // const validateYear = () => {
  //   const year = ref.current.value
  //   const yearNow = new Date().getFullYear();
  //   if(year && year.length == 4) {
  //     if(yearNow - year >= 21 ) {
  //       birthYear = year;
  //       setYearError(null)
  //     } else {
  //       setYearError('You must be 21 years old');
  //     }
  //   } else {
  //     setYearError('Year of birth is requered field');
  //   }
  // }

  const onSubmit = (data) => {
    birthYear&&sendForm(data).then(resp => {
      if(resp.error) {
        setLoginError( "A user with the same email address already exists")
      } else { 
        const date = (new Date()).getDate();
        const obj = JSON.stringify({user: resp, date: date});
        sessionStorage.setItem("userData",  obj);
        dispatch(addCurrntUser(resp)) 
        history.push("/");
      }
    });
  }

  const toggleClass = () => {
    setPasswordShown(!passwordShown);
  };

  const setPasswordVisability = () => {
    setPasswordShown(passwordShown ? false : true);
    toggleClass();
  };

  const allertClass = {
    border: "1px solid #D4335B"
  };
 
  const fullNameInput = register('full_name');
  const userNameInput = register('user_name');
  const emailInput = register('email');
  const zipCodeInput = register('zip_code');
  const passwordInput = register("password");
  const agreeRules = register('agree_rules');
  const agreeNews = register('aree_news');
  const yearOfBirth = register('birth_year');

  return (
  <div className={st.login_wrapper}>
    <div className={st.login_page}>
      <Link to={"/presentation"} className={st.logo_link}>
      	<Logo />
      </Link>
      <h2 className={st.page_title}>Create an Account</h2>
      <form onSubmit={handleSubmit(onSubmit)} className={st.sign_up}>
        {/* <h4 className={st.error}>{loginError}</h4> */}
        <label className={st.label_input}>
          <div className={st.title_wrapper}>
            <span>Full Name</span>
            {errors.full_name&&<span className={st.error}>{errors.full_name.message}</span>}
          </div>
          <CustomInput 
            styleInput={errors.full_name ? allertClass : {}}
            inputType="text" 
            placeholderText="Enter your full name" 
            inputName={fullNameInput.name} 
            {...fullNameInput}
          />
        </label>
        
        <label className={st.label_input}>
          <div className={st.title_wrapper}>
            <span>Username</span>
            {errors.user_name&&<span className={st.error}>{errors.user_name.message}</span>}
          </div>
          <CustomInput 
            styleInput={errors.user_name ? allertClass : {}}
            inputType="text" 
            placeholderText="Create your username" 
            inputName={userNameInput.name} 
            {...userNameInput}
          />
        </label>

        <label className={st.label_input}>
          <div className={st.title_wrapper}>
            <span>Email Address</span>
            { errors.email&&<span className={st.error}>{errors.email.message}</span> }
            {loginError.length >0 && <div className={st.loginError}>{loginError}</div>}
          </div>
          <CustomInput 
            styleInput={(errors.email || loginError.length >0 )? allertClass : {}}
            inputType="email" 
            inputName={emailInput.name} 
            {...emailInput}
            placeholderText="example@gmail.com"
          />
        </label>

        <div className={st.two_inputs_in_line}>
          
          <label className={`${st.label_input} ${st.two_inputs_in_line_label}`}>
            <div className={st.title_wrapper}>  
              <span>Zip Code</span>
              {errors.zip_code&&<p className={st.error}>{errors.zip_code.message}</p>}
            </div>
            <CustomInput 
              styleInput={errors.zip_code ? allertClass : {}}
              inputType="text" 
              inputName={zipCodeInput.name} 
              placeholderText="e.g. 100141"
              {...zipCodeInput}
            />
          </label>
          
          <label className={`${st.label_input} ${st.custom_select}`}>
            <div className={st.title_wrapper}>
              <span>Year of Birth</span>
              {birthYear.length < 1 && errors.birth_year && <p className={st.error}>{errors.birth_year.message}</p>}
            </div> 
            <CustomSelect
              {...yearOfBirth}
              styleInput={(birthYear.length < 1 && errors.birth_year) ? allertClass : {}}
              placeholderText="YYYY"
              inputName="birth_year"
              options={options}
              selectHandler={changeYearOfBirth}
              activeValue={birthYear}
              inputValue={birthYear}
            />
          </label>
        </div>
     
        <label className={`${st.label_input} ${st.password}`}>
          <div className={st.title_wrapper}>
            <span>Password</span>  
            {errors.password&&<p className={st.error}>{errors.password.message}</p>}
          </div>
          <CustomInput
            styleInput={errors.password ? allertClass : {}}
            inputType={passwordShown ? "text" : "password"} 
            inputName={passwordInput.name}  
            placeholderText="Min 8 characters" 
            {...passwordInput}
          />
          <button type="button" className={passwordShown ? `${st.eye}` : `${st.eye_off}`} onClick={setPasswordVisability}/>
        </label>

        <div className={st.checkboxes}>
          <div className={st.checkbox_wrapper}>
            <label className={st.checkbox_container}> 
              <CustomInput inputType="checkbox" inputName={agreeRules.name} 
                {...agreeRules}
              />
              <span className={st.checkmark}></span>
              I agree to the &nbsp;
            </label>
            <Link to="/terms_conditions">Terms &amp; Conditions</Link>
          </div>

          <div className={st.checkbox_wrapper}>
            <label className={st.checkbox_container}> 
              <CustomInput inputType="checkbox" inputName={agreeNews.name}  defaultChecked
                {...agreeNews}
              />
              <span className={st.checkmark}></span>
              Sign me up for&nbsp;
            </label>
            <a href="https://www.onlyplayers.com/">OnlyPlayers&nbsp;</a> 
            <span className={st.color_white}>newsletter</span>
          </div>
        </div>
        
        <ButtonSubmit type="button">SIGN UP</ButtonSubmit>

        <div className={st.bottom_question}>
          <span>Already have an account? </span>
          <Link to={"/signIn"}>Log in</Link> 
        </div>
      </form>

    </div>
  </div>
  )
}
