import st from './paginationBlock.module.scss'

const PaginationBlock = ({object, setParameters}) => {


  const onClickHandler = (number) => {
    number && setParameters('url', `https://megasports01.herokuapp.com/project/user_rates/?page=${number}`)
  }
  
  let buttonsArray = [];
  const pagesCount =  Math.ceil(object.rateCount / 7);

  let currentPage = object.nextPage ? 
    (object.nextPage && parseInt(object.nextPage.slice(-1)) - 1) : 
    (object.previousPage && parseInt(object.previousPage.slice(-1)) + 1);
  
  if (!currentPage && object.previousPage) {
    currentPage = 2;
  }

  if(pagesCount < 6) {
    for(let i = 1; i <= pagesCount && i < 6; i++) {
      buttonsArray.push(
        i === currentPage ? 
        <li key={i} onClick={(e) => {onClickHandler(i)}} className={`${st.button_page} ${st.active}`}>{i}</li> :
        <li key={i} onClick={(e) => {onClickHandler(i)}} className={st.button_page}>{i}</li>
      )
    }
  } else if (pagesCount > 5 && currentPage < 4) {
    for(let i = 1; i <= 3; i++) {
      buttonsArray.push(
        i === currentPage ? 
        <li key={i} onClick={(e) => {onClickHandler(i)}} className={`${st.button_page} ${st.active}`}>{i}</li> :
        <li key={i} onClick={(e) => {onClickHandler(i)}} className={st.button_page}>{i}</li>
      )
    };
    buttonsArray.push( <li key="dots" className={st.button_page}>...</li> );
    buttonsArray.push( <li key="last" className={st.button_page} onClick={() => {onClickHandler(pagesCount)}}>{pagesCount}</li> );
  } else if (currentPage > 3 && currentPage < pagesCount -2) {
    buttonsArray.push(<li key="1" className={st.button_page} onClick={() => {onClickHandler(1)}}>1</li>);
    buttonsArray.push(<li key="dots1" className={st.button_page} >...</li>);
    buttonsArray.push(<li key="cur" className={`${st.button_page} ${st.active}`}>{currentPage}</li>);
    buttonsArray.push(<li key="dots2" className={st.button_page} >...</li>);
    buttonsArray.push(<li key="last" className={st.button_page} onClick={() => {onClickHandler(pagesCount)}}>{pagesCount}</li>);
  } else if (currentPage >= (pagesCount-2)) {
    buttonsArray.push(<li key="1" className={st.button_page} onClick={() => {onClickHandler(1)}}>1</li>);
    buttonsArray.push(<li key="dots1" className={st.button_page}>...</li>);
    (pagesCount-2) === currentPage ? 
    buttonsArray.push(<li key="3" className={`${st.button_page} ${st.active}`} onClick={() => {onClickHandler(pagesCount-2)}}>{pagesCount-2}</li>) :
    buttonsArray.push(<li key="3" className={st.button_page} onClick={() => {onClickHandler(pagesCount-2)}}>{pagesCount-2}</li>);
    (pagesCount-1) === currentPage ? 
    buttonsArray.push(<li key="4" className={`${st.button_page} ${st.active}`} onClick={() => {onClickHandler(pagesCount-1)}}>{pagesCount-1}</li>) :
    buttonsArray.push(<li key="4" className={st.button_page} onClick={() => {onClickHandler(pagesCount-1)}}>{pagesCount-1}</li>);
    (pagesCount) === currentPage ? 
    buttonsArray.push(<li key="5" className={`${st.button_page} ${st.active}`} onClick={() => {onClickHandler(pagesCount)}}>{pagesCount}</li>) :
    buttonsArray.push(<li key="5" className={st.button_page} onClick={() => {onClickHandler(pagesCount)}}>{pagesCount}</li>)
  }

  return (
    <div className={st.paginationBlock}>
      { currentPage === 1 ?
        <div className={st.button_prev} aria-disabled='true' onClick={()=>{
          onClickHandler((currentPage - 1) > 0 ? (currentPage - 1) : null)}}/> :
        <div className={st.button_prev} aria-disabled='false' onClick={()=>{
          onClickHandler((currentPage - 1) > 0 ? (currentPage - 1) : null)}}/>
      }
      
      <ul className={st.buttonsList}>
        {buttonsArray}
      </ul>

      { currentPage === pagesCount ? 
        <div className={st.button_next} aria-disabled='true' onClick={()=>{
          onClickHandler((currentPage + 1) <= pagesCount ? (currentPage + 1) : null)}}/> :
        <div className={st.button_next} aria-disabled='false' onClick={()=>{
          onClickHandler((currentPage + 1) <= pagesCount ? (currentPage + 1) : null)}}/>
      }
    </div>
    
  )
}

export default PaginationBlock;