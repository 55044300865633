import { NavLink } from 'react-router-dom'
import st from './navigationBar.module.scss'

const PresentationNav = (props) => {
  

  return (
    <div className={st.navigation}>
      <div className={st.navigation_wrapper}>
        {/* <a href="/">Home</a> */}
        <NavLink to="/presentation" exact={true} activeClassName={st.selected} className={st.navigation_link}>Home</NavLink>
      </div>
      <div className={st.navigation_wrapper}>
        {/* <a href="/">How To Play</a> */}
        {props.anchor}
      </div>
      
    </div>
  )
}

export default PresentationNav