import React from 'react';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { addFootballLineupPlayer, changaeFootballPlayerStatus, removeLineupFootballPlayer } from '../../../../../../store/reduser';
import DropdownButton from '../../../../../UI/buttons/dropdownButton/dropdownButton';
import st from './footballAvalieblePlayer.module.scss'

function FootballAvalieblePlayer(props) {
  const game = props.player.game_name;
  const cross = game.indexOf('+');
  const nameFirstTeam = game.slice(0, cross);
  const nameSecondTeam = game.slice(cross+1);
  const playerName = props.player.name;
  const team = props.player.team_name;
  const homeRuns = props.player.home_runs;
  
  const date = new Date(props.player.game_time)

  const dispatch = useDispatch();

  const lineupPlayers = useSelector(state => state.footballLineUpPlayers);

  const checkingPlayer = (player) => {
    player.checked = !player.checked;
    dispatch(changaeFootballPlayerStatus(player))
  }

  const onclickHandlerAdd = () => {
    if(!lineupPlayers[props.pos].id) {
      checkingPlayer(props.player);
      dispatch(addFootballLineupPlayer({[props.pos]: props.player}));
    }
  }

  const onclickHandlerAdded = () => {
    checkingPlayer(props.player);
    dispatch(removeLineupFootballPlayer({[props.pos]: {}}));
  }

  const [visible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!visible)
  }

  return ( 
    <li className={st.football_line}>
      <div className={st.avalieblePlayer_line}>

        <DropdownButton toggleDropdown={toggle} styles={st.dropdown_show}/>

        <span className={st.playerName}>{playerName}</span>
        <span className={st.homeRuns}>{homeRuns}</span>
        
        {props.player.checked ? 
          <button type="button" className={st.addedButton} onClick={onclickHandlerAdded}/> : 
          <button type="button" className={`${st.addButton}`} disabled={lineupPlayers[props.pos].id && true} onClick={onclickHandlerAdd}/>
        }
      </div>
      
      {visible &&
        <div className={st.playerDropdown_content}>
          <div className={`${st.playerDropdown_content_wrapper} ${st.playerDropdown_content_team}`}>
            <span className={st.title}>Team:</span>
            <span>{team}</span>
          </div>

          <div className={`${st.playerDropdown_content_wrapper} ${st.playerDropdown_content_game}`}>
            <span className={st.title}>OPP:</span>
            <span>@{nameFirstTeam === props.player.team_short_name ? nameSecondTeam : nameFirstTeam}</span>
          </div>
          
          <div className={`${st.playerDropdown_content_wrapper} ${st.playerDropdown_content_position}`}>
            <span className={st.title}>DATE:</span>
            <span>{`${date.toLocaleString("en-US", {weekday: "short", month:"2-digit",day:"2-digit"}).toUpperCase()}`} <br /> 
		  {`${date.toLocaleString("en-US", {hour: "numeric", minute: "numeric",timeZoneName:"short",})}
		      ` }
            </span>
          </div>
        </div>
      }
    </li>
  )
}

export default FootballAvalieblePlayer
