import st from './userDropDown.module.scss';
import { addCurrntUser, clearLineupPlayers} from '../../../../../store/reduser';
import { useDispatch } from "react-redux";
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";


const UserDropDown = (props) => {

  const userName = useSelector(state => state.currentUser&&state.currentUser.user_name);

  const rate = useSelector(state => state.userRates)

  const dispatch = useDispatch();

  const history = useHistory();

  const logout = () => {
    dispatch(addCurrntUser(null));
    dispatch(clearLineupPlayers());
    sessionStorage.userData = null;
    history.push("/");
  }
  

  return  ( 
    <div onClick={props.onClick} className={st.wrapper}>
      <div className={st.dropdown_content} >
        <div className={st.close_button} onClick={props.onClick}>
          <div className={st.close_button_wrapper}></div>
        </div>
        <div className={st.dropdown_content_user}>
          <div className={st.user_avatar} />
          <div className={st.user}>
            <span className={st.user_title}>{userName}</span>
            {/* <div className={st.user_name}>{rate}</div> */}
          </div>
        </div>
        
        <div className={st.statistic}>
          {/* <div className={st.statistic_text}>
            <span>Weekly Leaderboard Entries:</span>
            <span className={st.statistic_text_entries}>10</span>
          </div> */}
          <div className={st.statistic_text}>
            <span>Monthly Qualified Entries: </span>
            <span className={st.statistic_text_entries}>{rate}</span>
          </div>
        </div>

        <ul className={st.dropdown_content_navigation}>
          {/* <li className={st.link_wrapper}>
            <NavLink to="/fantasygames/baseball" exact={true} activeClassName={st.selected} className={st.link}>
              MegaSports Baseball
            </NavLink>
          </li> */}
          <li className={st.link_wrapper}>
            <NavLink to="/fantasygames/football" exact={true} activeClassName={st.selected} className={st.link}>
              MegaSports Football
            </NavLink>
          </li>
          <li className={st.link_wrapper}>
            <NavLink to="/fantasygames/mypicks" exact={true} activeClassName={st.selected} className={st.link}>
              My Picks
            </NavLink>
          </li>
          {/* <li className={st.link_wrapper}>
            <NavLink to="/" exact={true} activeClassName={st.selected} className={st.link}>
              Rules and Scoring
            </NavLink>
          </li> */}
        </ul>

        <div className={st.logout_wrapper} onClick={logout} >
          <div className={st.logout} />
          <span>Log Out</span>
        </div>
      </div>
    </div>
    
  )
}

export default UserDropDown