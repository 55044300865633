import React, { useState } from 'react'
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import st from './signIn.module.scss'
import CustomInput from '../../UI/customInput/customInput';
import ButtonSubmit from '../../UI/buttons/buttonSubmit/buttonSubmit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { useDispatch } from 'react-redux';
import { addCurrntUser } from '../../../store/reduser';
import Logo from '../../UI/buttons/logo/logo';

export const SignIn = () => {

  const history = useHistory();

  const [passwordShown, setPasswordShown] = useState(false);

  const [loginError, setLoginError] = useState("")
  
  const dispatch = useDispatch()

  const schema = yup.object().shape({
    email: yup.string()
      .required('Email or username is required field')
      .min(8, 'Username must have at least 8 characters'),
    password: yup.string()
      .required('Password is required field')
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, "Password may contain one capital later and one number")
      .min(8, 'Min 8 characters')
  });

  const toggleClass = () => {
    setPasswordShown(!passwordShown);
  };

  const setPasswordVisability = () => {
    setPasswordShown(passwordShown ? false : true);
    toggleClass();
  };

  const {register, handleSubmit, 
    formState:{ 
      errors
    }
  } = useForm({resolver: yupResolver(schema)});

  const emailInput = register('email');
  const passwordInput = register('password');

  const alertClass = {
    border: "1px solid #D4335B"
  }

  const sendForm = (data) => {
    const body = {"email": data.email, "password": data.password}
    return fetch("https://megasports01.herokuapp.com/project/login/", {
      method: 'POST',
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    }).then( response => {
      return response.json()
    })
  }


  const onSubmit = (data) => {
    sendForm(data).then(resp => {
      if(resp.error) {
        setLoginError("Wrong email or password")
      } else { 
        dispatch(addCurrntUser(resp)) ;
        const date = (new Date()).getDate();
        const obj = JSON.stringify({user: resp, date: date});
        sessionStorage.setItem("userData",  obj);
        history.push("/");
      };
    });
  }
  
  
  return (
    <div className={st.login_wrapper}>
        <div className={st.login_page}>
          <Link to={"/presentation"} className={st.logo_link}>
          	<Logo />
          </Link>
          <h2 className={st.page_title}>welcome back!</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={st.sign_in}>
            <h4 className={st.login_error}>{loginError}</h4>

            <label className={st.label_input}>
              <div className={st.title_wrapper}> 
                <span>Email or Username</span>
                {errors.email&&<span className={st.error}>{errors.email.message}</span>}
              </div>
              <CustomInput 
                styleInput={errors.email ? alertClass : {}}
                placeholderText="example@gmail.com" 
                inputType="text"
                inputName={emailInput.name} 
                {...emailInput}
              />
            </label>
            
            <label className={`${st.label_input} ${st.sign_in_pass}`}>
              <div className={st.title_wrapper}>
                <span>Password</span>  
                {errors.password&&<span className={st.error}>{errors.password.message}</span>}
              </div>
              <CustomInput
                styleInput={errors.password ? alertClass : {}}
                inputType={passwordShown ? "text" : "password"} 
                inputName={passwordInput.name}  
                placeholderText="Min 8 characters" 
                {...passwordInput}
              />
              <button type="button" className={passwordShown ? `${st.eye}` : `${st.eye_off}`} onClick={setPasswordVisability}/>
            </label>
            
            
            <div className={`${st.bottom_question} ${st.question}`}>
              <Link to={"/passwordRecover"}>Forgot password?</Link>
            </div>  
            <ButtonSubmit type="submit">SIGN IN</ButtonSubmit>   

            <div className={st.bottom_question}>
              <span>Don't have an account? </span>
              <Link to={"/signUp"}>Sign up</Link>
            </div>

          </form>
        </div>
      </div>
  )
}
