import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadGames } from "../store/reduser";

const useFetchGames = (sport) => {
  const dispatch = useDispatch();

  const url = sport === 'baseball' ?  "https://megasports01.herokuapp.com/baseball/today_games_front/" : 
  "https://megasports01.herokuapp.com/football/weekly_games_front/"

  useEffect(() => {
    const getGames = () => {
      fetch( url, 
        {
          method: 'GET',
          headers: {
            "Accept": "application/json",
          },
        }).then( response => {
          return response.json()
        }).then (resp => {
          if(!resp.error) {
            let data = Object.values(resp.games_count)[0];
            dispatch(loadGames(data.reverse()));
            return data
          } else {
            dispatch(loadGames([]))
          }
        }
      )
    }
    getGames();
  }, [dispatch])
}

export default useFetchGames