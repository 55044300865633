import React from 'react';

const useViewport = () => {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [heigth, setHeight] = React.useState(window.innerHeight);
  const handleWindowResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }
  React.useEffect(()=> {
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [])

  return {width, heigth}
}

export default useViewport


