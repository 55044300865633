import { useState } from "react";
import { useSelector } from "react-redux";
import st from "./userInfo.module.scss"
import UserDropDown from "../userDropDown/userDropDown";

function UserInfo(props) {

  const userName = useSelector(state => state.currentUser&&state.currentUser.user_name);
  const [visible, setVisible] = useState(false);
  const toggle = () => {
    setVisible(!visible)
  }

  return (
    <div className={st.user_block} onClick={toggle}>
      <div className={st.user_avatar} />
      <div className={st.user}>
        <span className={st.user_title}>{userName}</span>
        <div className={st.user_name}>{props.wonRate}</div>
      </div>
      <div className={st.user_dropdown} />
      {visible&&<UserDropDown onClick={toggle} wonRate={props.wonRate}/>}
    </div>
  )
}

export default UserInfo