
import classes from './errorModal.module.scss'
import { Link, useLocation } from "react-router-dom"
import football from "../../../../assets/images/football_ball.svg"
import baseball from "../../../../assets/images/basb-ball.svg"

function ErrorModal(props) {

  const location = useLocation();

  const image = (location.pathname === "/fantasygames/football") ? football : baseball;

  return (
    <div className={classes.modal_ticket_cansel}>
      <div className={classes.modal_icon_canseled} style={{backgroundImage: `url(${image})`, backgroundRepeat: "no-repeat", backgroundSize: "cover"}}></div>
      <h2 className={classes.modal_title} style={{marginBottom: 20}}>
        {props.title}
      </h2>
      <div className={classes.modal_text}>
       {props.message}
      </div> 
      <div className={classes.modal_link}>
        <Link to={"/fantasygames/mypicks"} className={classes.link}>View all my picks</Link>
      </div>
    </div>
  )
}

export default ErrorModal

