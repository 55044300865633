import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { loadToadayPlayers, loadToadayFotballPlayers } from "../store/reduser";

const useFetchPlayers = (condition, sport) => {
  const dispatch = useDispatch();

  const url = sport === 'baseball' ? "https://megasports01.herokuapp.com/baseball/today_players_front/" :
   "https://megasports01.herokuapp.com/football/weekly_players_front/"

  useEffect(() => {
    const getPlayers = () => {
      fetch( url, 
      {
        method: 'GET',
        headers: {
          "Accept": "application/json",
        },
      }).then( response => {
        return response.json()
      }).then (resp => {
        if (sport === 'baseball') {
          const players = resp.results;
          const loadedPlayers = players.map(player => ({...player, checked: false}));
          dispatch(loadToadayPlayers(loadedPlayers))
        } else {
        
          for(let list of Object.keys(resp)) {
            let item = resp[list].map(player => ({...player, checked: false}));
            resp[list] = item;
          };
          dispatch(loadToadayFotballPlayers(resp));
        }
      })
    }
    if (condition){
      getPlayers()
    } else {
      dispatch(loadToadayPlayers([]));
      dispatch(loadToadayFotballPlayers({"RB": [], "QB": [], "WR_TE": []}))
    }
  }, [dispatch, condition])
}

export default useFetchPlayers
