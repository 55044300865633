import FantasyGames from './fantasyGames/fantasyGames.js'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { SignIn } from './loginPage/signIn/signIn.js'
import { SignUp } from './loginPage/signUp/signUp.js'
import PasswordCreate from './loginPage/passwordCreate/passwordCreate.js'
import PasswordRecover from './loginPage/passwordRecover/passwordRecover.js'
import PresentationFootball from './presentation/presentationFootball.js'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min'
import {Conditions} from './conditions/conditions.js'

const  App = () => {

  return(
    <Router>
      <Switch>
        <Route exact path="/">
            <Redirect to="/fantasygames/football" />  
        </Route>
        <Route path="/signUp" component= {SignUp} />
        <Route path="/signIn" component={SignIn} />
        <Route path="/terms_conditions" component={Conditions} />
        <Route path="/passwordRecover" component={PasswordRecover} />
        <Route path="/passwordCreate" component={PasswordCreate} />
        <Route path="/presentation" component={PresentationFootball} />
        <Route path="/fantasygames" component={FantasyGames} />
        <Route path="*" >
          <Redirect to="/fantasygames/football" />
        </Route>
      </Switch>
    </Router>
  )
}

export default App