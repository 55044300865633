import React from 'react'
import st from './dropdownButton.module.scss'

function DropdownButton ({toggleDropdown, styles, props}) {
  return (
    <label className={`${st.dropdown_button} ${styles}`}>
      <input type="checkbox" onClick={toggleDropdown}/>
      <span className={st.dropdown_button_icon}></span>
    </label>
  )
}

export default DropdownButton
