import st from './buttonSubmit.module.scss'

function ButtonSubmit(props) {
  return (
    <button className={st.button_submit} onClick={props.onClick} disabled={props.disabled} style={props.style} >
      {props.children}
    </button>
  )
}

export default ButtonSubmit