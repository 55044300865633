//-------displayed error when no next game
  // if(!nearestGame&&games.length > 0) {
  //   !error.displayed&&errorVisible();
  // }

  //----------displayed error once !!!

// const errorVisible = () => { 
  //   if(error.visible) {
  //     setError({
  //       visible: false,
  //       title: "",
  //       message: "",
  //       displayed: true
  //     })
  //   } else {
  //     setError(
  //       location.pathname === "/fantasygames/football" ? 
  //       {
  //         visible: true,
  //         title: " This week’s games are locked",
  //         message: "Log back in Tuesday at 12pm ET to make next week's picks!",
  //         displayed: true
  //       } :
  //       {
  //         visible: true,
  //         title: " today’s games have begun",
  //         message: "Login tomorrow morning to place your picks!",
  //         displayed: true
  //       }
  //     )
  //   }
  // };

  //---------for next game date
    // const getNearestGame = (nearestGame) => {
    //   if(games.length>0) {
        
        // let date =new Date(nearestGame.time)
        // var options = {
        //   hour: 'numeric',
        //   minute: 'numeric',
        //   weekday: "long",
        //   month: 'long',
        //   day: 'numeric',
        //   year: 'numeric',
        //   timeZoneName: 'short'
        // };
        //date = date.toLocaleString("en-US", options)
        //return {
          // hour: date.toLocaleString("en-US", {hour: 'numeric', minute: 'numeric', timeZoneName: 'short'}),
          // minute: date.toLocaleString("en-US", {minute: 'numeric'}),
          // weekday: date.toLocaleString("en-US", {weekday: 'long'}),
          // month: date.toLocaleString("en-US", {month: 'long'}),
          // day: date.toLocaleString("en-US", {day: 'numeric'}),
          // year: date.toLocaleString("en-US", {year: 'numeric'}),
          // timeZone: date.toLocaleString("en-US", {timeZoneName: 'short'})
      //   }
      // }
    // }

   //var gameObj = getNearestGame(nearestGame);

     // if(nearestGame) {
  //   const restTime = (nearestGame) => {
  //     if(games.length>0){
  //       const gameTime = Date.parse(nearestGame.time);
  //       const currentTime = Date.now();
  //       const restTime = gameTime - currentTime;
  //       return restTime
  //     }
  //   }

  //   const timer = () => {
  //     setTime(restTime(nearestGame))
  //   };

  //   setTimeout(timer, 1000);
  // } 



import React, { useCallback, useEffect } from 'react';
import st from './nextGameDate.module.scss';
import { useSelector } from "react-redux"
import useFetchGames from '../../../hooks/useFetchGames';
import { useState } from 'react';
import useFetchPlayers from '../../../hooks/useFetchPlayers';
import ModalWindow from '../../UI/modals/modalWindow/modalWindow';
import ErrorModal from '../../UI/modals/errorModal/errorModal';
import { useHistory, useLocation } from 'react-router-dom';

const  NextGameDate = (props) =>  {

  useFetchGames(props.sport);

  const [time, setTime] = useState(null);

  const games = useSelector(state => state.gameList);

  const location = useLocation()

  let isBlocking = false;
  
  const history = useHistory();

  const nearestGame = games.find(game => {
    const now = Date.now();
    const gameTime = Date.parse(game.time);
    return (gameTime > now);
  });

  const lastGame = games.slice(-1)[0];

    //----------imulate no more games
  //const nearestGame = undefined;

  const [error, setError] = useState(
    {
      visible: false,
      title: "",
      message: "",
      displayed: false
    }
  );

  const timerCheck = useCallback ((nearestGame) => {   
    if(nearestGame) {
      const gameTime = Date.parse(nearestGame.time);
      const currentTime = Date.now();
      const restTime = gameTime - currentTime;
      if (restTime < 0) {

        setTime(currentTime)
      }
    }
    const dateBlock = new Date();
    const gameBlock = nearestGame? new Date(nearestGame.time) : new Date();
    const blockDay = dateBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York',hour: '2-digit', weekday: 'short' }).slice(0,3);
    const blockHour = parseInt( dateBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York',hour: '2-digit' }).slice(-2));
    const blockMinute = parseInt( dateBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York', minute: 'numeric' }).slice(-2));
    const gameHour = parseInt( gameBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York',hour: '2-digit' }).slice(-2));
    const gameMinute = parseInt( gameBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York', minute: 'numeric' }).slice(-2));
    //if((blockDay === 'Mon' && blockHour >= 16 && blockMinute > 19) || (blockDay === 'Mon' && blockHour > 17) || (blockDay === 'Tue' && blockHour < 12) ) {
    if((blockDay === 'Mon' && blockHour >= gameHour && blockMinute >= gameMinute) || (blockDay === 'Tue' && blockHour < 2) ) {
      if(!isBlocking) {

        isBlocking = true; 
        setError(
          {
            visible: true,
            title: " This week’s games are locked",
            message: "Log back in Tuesday at 2:00AM ET to make next week's picks!",
            displayed: true
          } 
        ) 
      }    
    } else if(isBlocking) {

      history.push('/signIn')
    }
  });

  useEffect(() => {
    const timerInterval = setInterval(timerCheck, 1000, nearestGame);
    return () => { 
      clearInterval(timerInterval) 
    }
  }, [nearestGame]);
    
  const errorVisible = () => {
    console.log('errorVisible')
  };
  
  useFetchPlayers(nearestGame, props.sport);

 //console.log(dateBlock.toLocaleDateString('en-GB', { timeZone: 'America/New_York',hour: '2-digit', weekday: 'short' })) ;

  let date = new Date();
  const date2 = (lastGame && date2==='undefined')? new Date(lastGame.time) : undefined;
  date.setDate(date.getDate() + ((7-date.getDay())%7+1) % 7);
  let month = date.toLocaleString("en-US", {month: 'long'});
  let day = date.toLocaleString("en-US", {day: 'numeric'});
  let year = date.toLocaleString("en-US", {year: 'numeric'});

  const hours = Math.floor(time / 3600000);
  const minutes = Math.floor(time / 60000) - hours * 60;
  const seconds = ((time % 60000) / 1000).toFixed(0);

  return (
    <div className={st.next_game_date}>
      {nearestGame ? (
       location.pathname === "/fantasygames/baseball" ? 
       <>
          <p className={st.time_back}>
            {hours}:{minutes<10?`0${minutes}`:minutes}:{seconds<10?`0${seconds}`:seconds}
          </p>
          <p className={st.next_game_text}>
            To get your picks in!
          </p>
        </> : 
        <>
          <p className={st.next_game_text}>
            Get your picks in by 
          </p>
          <p className={st.next_game_day}>
            MONDAY {(new Date(lastGame.time)).toLocaleString("en-US", {month: 'long'})} {day}, {year}
            {/* {gameObj.weekday.toUpperCase()} {gameObj.month.toUpperCase()} {gameObj.day}, {gameObj.year} */}
          </p>
          <p className={st.next_game_time}>
            {/* AT {gameObj.hour.toUpperCase()}! */}
            AT {parseInt( (new Date(lastGame.time)).toLocaleDateString('en-US', { timeZone: 'America/New_York',hour: '2-digit' }).slice(-5,-3))}:{parseInt( (new Date(lastGame.time)).toLocaleDateString('en-US', { timeZone: 'America/New_York', minute: 'numeric' }).slice(-2))} PM!
          </p>
        </>
        ) : (
          <>
            <p className={st.no_game_title}>TODAY`S GAMES HAVE BEGUN</p>
            <p className={st.next_game_text}>Login tomorrow morning <br/>to place your picks!</p>
          </>
        )
      }
      <ModalWindow visible={error.visible&&"true"} setVisible={errorVisible} blocking={error.title.slice(-6) === "locked"}>
        <ErrorModal  title={error.title} message={error.message} blocking={isBlocking} />
      </ModalWindow>
    </div>
  )
}

export default NextGameDate
