import st from "./footballLineUpBlock.module.scss"
import LoadingSpinner from "../../../../UI/loadingSpinner/loadingSpinner"
import FootballAvalieblePlayer from "./footballAvalieblePlayer/footballAvalieblePlayer"
import ButtonDelete from "../../../../UI/buttons/buttonDelete/buttonDelete"
import React from 'react';
import useSortableData from "../../../../../hooks/useSortData";
import { useSelector } from "react-redux";
import { HelperSpinner } from "../../../../../helpers/HelperSpinner";


const FootballLineUpBlock = (props) => {

  //const lineupPlayers = useSelector(state => state.lineUpPlayers);

  // const spinner = ["spiner", "spiner", "spiner", "spiner", "spiner", "spiner"]

  let todayPlayers = useSelector(state => state.todayFootballPlayers[props.pos]);
  
  const emptyArr = HelperSpinner(6);

  // if (todayPlayers < 1 ) {
  //   todayPlayers = spinner;
  // }

  const { players, requestSort } = useSortableData(todayPlayers);
  
  const lineupPlayer = useSelector(state => state.footballLineUpPlayers[props.pos])

  return (
    <div className={st.lineup_block}>
      {todayPlayers.length > 0 ? 
      
      <div className={st.lineup_block_bet}>
        <div className={st.lineup_block_bet_player}>
          {lineupPlayer.id ? 
            <> 
              <div className={st.lineUpItemBlock}>
                <div className={st.lineUpPlayer}>{lineupPlayer.name}</div>
                <div className={st.lineUpTeam}>{lineupPlayer.team_name}</div>
                <div className={st.lineUpTouchdown}>{lineupPlayer.home_runs}</div>
                <div className={st.lineUpTouchdownIcon}></div>
              </div>
              <ButtonDelete player={lineupPlayer} className={st.buttonDelete} pos={props.pos}/>
            </> : 
            <div className={st.lineup_block_bet_player_empty}>
              {
                `Pick your ${props.position}` 
              }
            </div>
          }
        </div>
      </div> : 

      <div className={`${st.lineUpList_emptyList} ${st.lineup_block_bet_player_empty}`}>
        <span>Log in tomorrow to make your picks!</span>
      </div>
      }

      <div className={st.lineup_block_players_list}>

        <div className={st.sort_buttons}>
          
          <div className={`${st.sort_buttons_wrapper} ${st.sort_buttons_player}`} onClick={() => requestSort('name')}>
            <span className={st.sort_buttons_wrapper_text}>player</span>
          </div>
          
          <div className={`${st.sort_buttons_wrapper} ${st.sort_buttons_touchdown}`} onClick={() => requestSort('home_runs')}>
            <span className={st.sort_buttons_wrapper_text}>Touchdowns</span>
          </div>

        </div>

        <ul className={st.players_list}>
          {/* {
            players && players.map((player, index) => {
              return player === "spiner" ? 
              <LoadingSpinner key={Math.random()}/> :
              <FootballAvalieblePlayer player={player} key={index} pos={props.pos} />
            }) 
          } */}
          { 
            todayPlayers.length < 1 ? 
            emptyArr.map(() => <LoadingSpinner key={Math.random()}/> ) : 
            players.map((player, index) => <FootballAvalieblePlayer player={player} key={index} pos={props.pos}/> ) 
          }
        </ul>
      </div>
    </div>
  )
} 

export default FootballLineUpBlock