import React from 'react'
import st from './headerLogo.module.scss'

const HeaderLogo = () => {
  return (
    <div className={st.header_logo}/>
  )
}

export default HeaderLogo
