import { Link } from "react-router-dom"
import st from "./condition.module.scss"
import useFetchPrise from '../../hooks/useFetchPrise';

const Conditions = () => {
  const prize = useFetchPrise();
  return (
    <div className={st.main_wrapper}>
      <h1 className={st.main_title}> Only Players MegaSports Football Official Rules</h1>
      <p className={st.top_paragraph}>NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR 
        CHANCES OF WINNING. ALL FEDERAL, STATE, LOCAL, AND MUNICIPAL LAWS AND 
        REGULATIONS APPLY. VOID WHERE PROHIBITED.
      </p>
      <ol className={st.condition_list}>
        <li>
          <span className={st.li_title}>Conditions of Entry</span>
          <p>
            By entering Only Players Free to Play MegaSports Football contest (the “Contest”)
            and accepting the terms herein, you agree to be bound by the following rules and 
            Goodsport Media’s Terms of Use Agreement located at "http://www.megasports.com/terms_conditions" (collectively, the “Rules”),
            which collectively constitute a binding agreement between you, on one hand, and
            Goodsport Media, Inc. (“Company”), on the other. Company and the website companies
            hosting and promoting the Contest, their respective parent companies, affiliates, 
            subsidiaries, advertising and promotion agencies, and all of their agents, officers, 
            directors, shareholders, employees, and agents are referred to individually and 
            collectively as the “Contest Entities”. These Rules apply only to the specific 
            Contest referenced above and not to any other Contest or contests of the Company, 
            the Contest Entities, or their affiliates. All entries submitted in accordance with 
            these Official Rules shall be hereinafter referred to as “valid” or “eligible” Entries.
          </p>
        </li>
        <li>
          <span className={st.li_title}>Eligibility</span>
          <p>
            The Contest is open to legal residents of the fifty (50) United States and 
            the District of Columbia who are at least eighteen (18) years old at the time 
            of entry. Employees of the Contest Entities and their respective parents, 
            subsidiaries, affiliates, and advertising and promotion agencies as well as 
            the immediate family (spouse, parents, siblings, and children) and household 
            members of each such employee are not eligible. The Contest is subject to federal, 
            state, and local laws and regulations. Winning a prize is contingent upon being 
            compliant with these Official Rules and fulfilling all other requirements set forth 
            herein.
          </p>
          <p>
            <span className={st.letter}>A.</span>Minors. The Contest is only open to persons 
            who, upon entering, are 
            lawful permanent residents of the United States and are at least 18 
            years old or older as of the date of Entry. If you are under the legal 
            age of majority in your state of residence (a “Minor”), you certify that 
            one of your parents or legal guardians must submit a signed, written 
            statement that they have read, understood, and agree to these Rules with 
            your Entry, and that such agreement constitutes acceptance of these Rules 
            on behalf of you and themselves.
          </p>
          <p>
          <span className={st.letter}>B.</span>Ineligible Participants. Contest void in Puerto
            Rico, U.S. Territories and possessions, outside of the United States, and where
            prohibited by law (other locations are considered the “Contest Territory”). 
            The Contest and any website pages and advertisements relating thereto, are intended 
            for viewing only within the United States. You are not authorized to participate in 
            the Contest if you are not located within the United States.
          </p>
        </li>
        <li>
          <span className={st.li_title}>Sponsor</span>
          <p>
            The Contest is sponsored by OnlyPlayers, LLC, located at 25 Hillcrest Avenue, 
            Darien, CT 06820. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Contest Period</span>
          <p>
            The Contest begins on the first day of each month at 12:00 a.m. 
            Eastern Time Zone and ends on the final day of each month at 11:59 p.m. 
            Eastern Time Zone (the ”Contest Period”). Entries that are submitted before 
            or after the Contest Period will be disqualified. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>How to Enter </span>
          <p>
            <span className={st.sub_paragraph}>
              Online Entry: This method of entry will be available by visiting our website 
              (www.megasports.com) and following the directions provided to fill out the entry 
              information, and submit. A participant must provide name, valid email address, 
              password age and zip code prior to submitting first entry. Upon subsequent entries, 
              a participant will be required to provide email and password at sign-in.
            </span>
          </p>
          <p>
            Limit one (1) entry per person, per email address, and per household for 
            the duration of the Contest Period, regardless of method of entry. Entries 
            received from any person, e-mail address, or household in excess of the stated 
            limitation will be void. All entries become the property of the Sponsor and will 
            not be acknowledged or returned. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Prize Drawing </span>
          <p>
            On or about the 10<sup>th</sup> day of the following month, the Sponsor will select 
            potential winners in a random drawing from among all eligible entries received in 
            the previous month. The odds of being selected depend on the number of entries 
            received. The Sponsor will attempt to notify the potential winner via telephone or 
            email on or about the 12<sup>th</sup> day of the following month. . If the potential winner 
            cannot be contacted within five (5) days after the date of the first attempt to 
            contact him/her, the Sponsor may select an alternate potential winner in his/her 
            place at random from the remaining non-winning, eligible entries. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Winner Notification </span>
          <p>
            The potential winners will be notified by email, mail, or phone. 
            Each potential Grand and First Prize winner (parent/legal guardian if a minor 
            in his/her state of residence) will be required to complete, electronically 
            sign and submit a Declaration of Compliance within five (5) days of the date 
            notice or attempted notice is sent, in order to claim his/her prize. If a 
            potential winner cannot be contacted or fails to submit the Declaration of 
            Compliance within the required time period (if applicable), or the prize is 
            returned as undeliverable, the potential winner forfeits the prize. If the 
            potential winner is at least 18 but still considered a minor in his/her 
            jurisdiction of residence, Sponsor reserves the right to award the prize in 
            the name of his/her parent or legal guardian, who will be required to sign 
            the Declaration of Compliance on the potential winners behalf and fulfill 
            any other requirements imposed on winner set forth herein. Potential winners 
            must continue to comply with all terms and conditions of these Official Rules, 
            and winning is contingent upon fulfilling all requirements. In the event that 
            a potential winner is disqualified for any reason, Sponsor will award the 
            applicable prize to an alternate winner by random drawing from among all 
            remaining eligible entries. Only three (3) alternate drawings will be held, 
            after which the prize will remain un-awarded. Prizes will be fulfilled 
            approximately 8-10 weeks after the conclusion of the Contest.
          </p>
        </li>
        <li>
          <span className={st.li_title}>Prizes  </span>
          <p>
            One (1) winner shall receive the grand prize of U.S. &#36;{prize}. Up to four (4) 
            winners will each receive merchandise apparel with a retail value of up to U.S. 
            &#36;50. No transfer, cash alternative, or substitution permitted, except by Sponsor 
            in its sole discretion. Limit one prize per registered member. If the actual value 
            of the prize is less than the stated retail value, the difference will not be 
            awarded. The Prize is not redeemable for cash or transferable. The company 
            will not replace any lost or stolen prizes. The prize cannot be used in 
            conjunction with any other promotion or offer. Arrangements for fulfillment 
            of the prize will be made by Sponsor, and Company bears no responsibility for 
            the delivery of the prizes. Delivery or pick-up costs of the prize, insurance, r
            egistration, taxes, and any fees or costs associated with the prize are the sole 
            responsibility of the potential Winner. The prize is awarded “as is” with no 
            warranty or guarantee, either express or implied.
          </p>
        </li>
        <li>
          <span className={st.li_title}>General Conditions  </span>
          <p>
            In the event that the operation, security, or administration of the 
            Contest is impaired in any way for any reason, including, but not limited 
            to fraud, virus, bug, worm, unauthorized human intervention or other technical 
            problem, or in the event the Contest is unable to run as planned for any other 
            reason, as determined by Sponsor in its sole discretion, the Sponsor may, in its 
            sole discretion, either (a) suspend the Contest to address the impairment and then 
            resume the Contest in a manner that best conforms to the spirit of these Official 
            Rules or (b) terminate the Contest and, in the event of termination, award the prize 
            at random from among the eligible, non-suspect entries received up to the time of 
            the impairment. The Sponsor reserves the right in its sole discretion to disqualify 
            any individual it finds to be tampering with the entry process or the operation of 
            the Contest or to be acting in violation of these Official Rules or in an 
            unsportsmanlike or disruptive manner. Any attempt by any person to damage the 
            website or undermine the legitimate operation of the Contest may be a violation 
            of criminal and civil law, and, should such an attempt be made, the Sponsor reserves 
            the right to seek damages (including attorney's fees) and any other remedies from any 
            such person to the fullest extent permitted by law. Failure by the Sponsor to enforce 
            any provision of these Official Rules shall not constitute a waiver of that provision. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Release and Limitations of Liability</span>
          <p>
            By participating in the Contest, entrants agree to release and hold harmless the 
            Sponsor, and each of their respective parents, subsidiaries, affiliates, 
            advertising and promotion agencies, other companies associated with the Contest, 
            and each of their respective officers, directors, employees, shareholders, 
            representatives, and agents (the Released Parties) from and against any claim or 
            cause of action arising out of participation in the Contest or receipt or use of 
            the prize (including any travel or activity related thereto), including, but not 
            limited to: (a) any technical errors associated with the Contest, including lost, 
            interrupted or unavailable Internet Service Provider (ISP), network, server, 
            wireless service provider, or other connections, availability or accessibility or 
            miscommunications or failed computer, satellite, telephone, cellular tower or 
            cable transmissions, lines, or technical failure or jumbled, scrambled, 
            delayed, or misdirected transmissions or computer hardware or software 
            malfunctions, failures or difficulties; (b) unauthorized human intervention 
            in the Contest; (c) mechanical, network, electronic, computer, human, printing 
            or typographical errors; (d) application downloads, (e) any other errors or 
            problems in connection with the Contest, including, without limitation, errors 
            that may occur in the administration of the Contest, the announcement of the 
            winner, the cancellation or postponement of the event and/or the flyover, if 
            applicable, the incorrect downloading of the application, the processing of 
            entries application downloads or in any Contest-related materials; or (f) 
            injury, death, losses or damages of any kind, to persons or property which may 
            be caused, directly or indirectly, in whole or in part, from entrants 
            participation in the Contest or acceptance, receipt or misuse of the prize 
            (including any travel or activity related thereto). Entrant further 
            agrees that in any cause of action, the Released Parties liability will be 
            limited to the cost of entering and participating in the Contest, and in no 
            event shall the entrant be entitled to receive attorney's fees. Released 
            Parties are also not responsible for any incorrect or inaccurate information, 
            whether caused by site users, tampering, hacking, or by any equipment or 
            programming associated with or utilized in the Contest. Entrant waives the 
            right to claim any damages whatsoever, including, but not limited to, punitive, 
            consequential, direct, or indirect damages. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Disputes</span>
          <p>
            Except where prohibited, each entrant agrees that any and all disputes, 
            claims and causes of action arising out of, or connected with, the Contest 
            or any prize awarded shall be resolved individually, without resort to any 
            form of class action, and exclusively by the appropriate court located in 
            Connecticut. All issues and questions concerning the construction, validity, 
            interpretation and enforceability of these Official Rules, entrants rights and 
            obligations, or the rights and obligations of the Sponsor in connection with 
            the Contest, shall be governed by, and construed in accordance with, the 
            laws of Connecticut, without giving effect to any choice of law or conflict 
            of law rules, which would cause the application of the laws of any jurisdiction 
            other than Connecticut. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>Privacy</span>
          <p>
            Any Entry information collected from the Contest shall be used only in a 
            manner consistent with the consent given by entrants at the time of Entry 
            and with Company’s On-line Privacy Policy, which can be found at: "http://www.megasports.com/terms_conditions". 
            As stated above, the Contest is not open to Minors under the age of majority 
            in their state of residence without the express consent of a parent or legal 
            guardian. In the event that you are under the age of thirteen (13) years and 
            submit Entries to Company, Company will delete, erase or destroy any and all 
            personally identifiable information that is obtained from the Entries following 
            the conclusion of the Contest Period. 
          </p>
        </li>
        <li>
          <span className={st.li_title}>General</span>
          <p>
            Noncompliance with any of these Official Rules may result in disqualification. 
            ANY VIOLATION OF THESE OFFICIAL RULES BY A WINNER OR ANY BEHAVIOR BY A WINNER 
            THAT WILL BRING SUCH WINNER OR CONTEST ENTITIES INTO DISREPUTE (IN COMPANY’S 
            SOLE DISCRETION) WILL RESULT IN SUCH WINNER’S DISQUALIFICATION AS A WINNER OF 
            THE CONTEST AND ALL PRIVILEGES AS A WINNER WILL BE IMMEDIATELY TERMINATED. 
            This Contest is subject to all applicable federal, state and local laws. All 
            federal, state or other tax liabilities (including income tax and vehicle 
            registration) arising from this Contest will be the sole responsibility of 
            the Winner. Any person attempting to defraud, deliberately damage any website, 
            or in any way tamper with or undermine the legitimate operation of the Contest 
            will be ineligible for prizes and may be prosecuted to the full extent of the law.
          </p>
        </li>
      </ol>
      <Link to="/signup">Go Back To Sign Up</Link>
    </div>
  )
}

export  {Conditions}
