import st from './customSelect.module.scss'
import React from "react"
    

const CustomSelect = React.forwardRef(({
  activeValue, 
  selectHandler, 
  queryKey, 
  placeholderText, 
  inputName, 
  styleSelect, 
  options,
  inputValue,
  styleInput,
  ...attrs
}, ref) => {

  const onOptionClicked =  (event) => {
    const dropDownCheckbox = document.getElementById(inputName + "checkBox");
    dropDownCheckbox.checked = false;
    selectHandler && selectHandler(queryKey, event.target.value)
  }

  return   (
    <div className={`${st.select_box} ${styleSelect}`}>
      <input type="checkbox" className={st.options_view_button} id={inputName + "checkBox"} />
      <div className={st.selected_value}>
        <input 
          style={styleInput}
          className={st.selected_value_input} 
          type="text"
          placeholder={placeholderText} 
          name={inputName}
          ref={ref}
          id={inputName}
          value={inputValue}
          {...attrs}
          readOnly
        />
      </div>
      <div className={st.options}>
         {options.map(option => (
          <label className={st.option} key={Math.random()} >
            <input 
              checked={activeValue == option.value} 
              onChange={onOptionClicked} 
              className={st.option_input} 
              type="radio" 
              name={inputName + "_radio"} 
              value={option.value}
            />
            <span className={st.option_label}>{option.name}</span>
            <span className={st.option_check}></span>
          </label>
        ))}

      </div>
    </div>
  )
}) 

export default CustomSelect
