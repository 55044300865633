
import PresentationNav from "./navigationBar/navigationBar";
import LoginButtons from "./loginButtons/loginButtons";
import st from "./presentationFootball.module.scss"
import { useHistory } from "react-router-dom";
import { Scrollchor } from 'react-scrollchor';
import trophy from '../../assets/images/cup2.svg'
import HeaderLogo from '../UI/buttons/logo/headerLogo'
import Logo from '../UI/buttons/logo/logo'
import kicker from '../../assets/images/football_main_photo.png'
import dashboard from '../../assets/images/dashboard1_1.png'
import useFetchPrise from '../../hooks/useFetchPrise';

const PresentationFootball = () => {

  const prize = useFetchPrise();

  const anchor = <Scrollchor to="#howToPlay">How To Play</Scrollchor>

  const navBar = <PresentationNav anchor={anchor}/>;

  const loginButtons = <LoginButtons />;

  const history = useHistory();

  const signUp = () => {
    history.push("/signup")
  };

  return (
    <div className={st.presentation} id="home">
      <header className={st.header}>
        {navBar}
        <HeaderLogo />
        {loginButtons}
      </header>
      
      <div className={st.wrapper}>
        <div className={st.background_container}>
          <div className={st.left_panel}>
            <div className={st.title}>
              Welcome to
            </div>
            <div className={st.title_accent}>
              MegaSports Football!
            </div>
            <div className={st.text_regular}>
              MegaSports Football is an exciting free-to-play pick’em game. Pick a quarterback, 
              running back, and a wide receiver/tight end who will score a touchdown this week. 
              If you are right, your qualified entry will give you a chance to win $1000 at the end 
              of the month.  You can pick up to ten times per week.  
            </div>
            <button type="button" onClick={signUp} className={st.play_button}>Play Now!</button>
          </div>
          <div className={st.right_panel}>
            <img src={kicker} alt="kicker" className={st.kicker_image}/>
          </div>
        </div>
          <div className={st.win}>
            <div className={st.inner_wrapper}>
              <div className={st.prize}>
                <img src={trophy} alt="trophy" className={st.prize_pic} />
              </div>
              <div className={st.prize_panel2}>
                <div>
                    <div className={st.prize_panel2_text}>you could win</div>
                    <div className={st.prize_panel2_amount}>${prize}!</div>
                </div>
                <div>
                  <button type="button" onClick={signUp} className={st.play_button}>Play Now!</button>
                </div>
              </div>
            </div>
          </div>

          <div className={st.screenshot}>
	    <div className={st.img_container}>
	      <img src={dashboard} alt="dashboard image" className={st.img_container_screenshot} />
	    </div>
          </div>
        
        <div className={st.footer_section}>
          <h2 className={st.title_second} id="howToPlay">
            MegaSports Football is easy and fun.
          </h2>

          <div className={st.steps}>
            <div className={`${st.step} ${st.step_one}`}>
              <div className={st.step_text_wrapper}>
                <div className={`${st.step_icon} ${st.icon_man}`} />
                <div className={st.step_title}>
                  Pick Your Players!
                </div>
                <div className={st.step_text}>
                  For this week's NFL games, pick one quarterback, one running back, and one wide receiver/tight end
                  who will score a touchdown. You can submit up to 10 entries per week all month long!
                </div>
              </div>
            </div>

            <div className={`${st.step} ${st.step_two}`}>
              <div className={st.step_text_wrapper}>
                <div className={`${st.step_icon} ${st.icon_bit}`} />
                <div className={st.step_title}>
                  Lock Your Picks!
                </div>
                <div className={st.step_text}>
                  Place your picks by noon each Sunday to play for the week.
                </div>
              </div>
            </div>
          </div>
          <button type="button" onClick={signUp} className={st.play_button}>Play Now!</button>
        </div>

        <div className={st.footer}>
          <div className={st.footer_top}>
            <div className={st.footer_logo}/>
            <ul className={st.nav}>
              <li className={st.nav_button}>
                <Scrollchor to="#home"> Home </Scrollchor>
              </li>
	      {/*
	      <li className={st.nav_button} >
                <Scrollchor to="#howToPlay">How To Play</Scrollchor>
              </li> */}
            </ul>
          </div>
          <div className={st.footer_bottom}>
            ©  MegaSports Powered By Only Players
          </div>
        </div>
      </div>
     
    </div>
  )
}

export default PresentationFootball
