import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setUserRates } from "../../../../store/reduser";
import st from "./rightBlock.module.scss";
import UserDropDown from "./userDropDown/userDropDown";
import UserInfo from "./userInfo/userInfo";
import { Link } from "react-router-dom";

function RightBlock() {

  const tickets = useSelector(state => state.currentUser&&state.currentUser.available_rates);

  const footballTickets = useSelector(state => state.currentUser&&state.currentUser.available_rates_football);

  const location = useLocation();
  const rate = (location.pathname === "/fantasygames/baseball") ? tickets : footballTickets;

  const [visible, setVisible] = useState(false);

  const wonRate = useSelector(state => state.userRates);

  const dispatch = useDispatch();
  
  const toggle = () => {
    setVisible(!visible)
  };
  const token = useSelector(state => state.currentUser&&state.currentUser.token);
  
  let body = {
    "access_token": token
  };
  
  useEffect(() => {
    fetch("https://megasports01.herokuapp.com/project/user_info/", 
      {
        method: 'POST',
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json"
        },
        body: JSON.stringify(body)
      }).then( response => {
        return response.json()
      }).then((resp) => {
        dispatch(setUserRates(resp.rates_won))
      })
  }, [])


  return (
    <div className={st.info_block} >
      
      <div className={st.daily_picks}>
        { (rate > 0) ? 
          <Link to={"/fantasygames/football"} className={st.daily_picks_link} /> : 
          <Link to={"/fantasygames/mypicks"} className={st.daily_picks_link}/>
        }
        <span className={st.daily_picks_title}>Weekly Picks Left</span>
        <div className={st.daily_picks_amount}>
          <div className={st.tickets} />
          <span className={st.tickets_number}>{rate}</span>
        </div>
      </div>
        
      <div className={st.message}>
        <div className={st.bell} />
      </div>

      <UserInfo wonRate={wonRate}/>

      <div className={st.burger}>
        <label className={st.burger_wrapper}>
          <input type="checkbox" className={st.burger_input} onClick={toggle} />
          <span className={st.burger_icon}></span>
          <span className={st.burger_icon}></span>
          <span className={st.burger_icon}></span>
          {visible&&<UserDropDown />}
        </label>
      </div>

      <div className={st.burger_dropdown} />
    </div>
  )
}

export default RightBlock