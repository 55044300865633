
import st from './myPickModal.module.scss'
import smile from '../../../../assets/images/smile.png'
import congratulation from '../../../../assets/images/congratulations.png'
import smileSpecs from '../../../../assets/images/smile_specs.png'
import ButtonSubmit from '../../buttons/buttonSubmit/buttonSubmit'
import { useHistory } from 'react-router-dom'
import useFetchPrise from '../../../../hooks/useFetchPrise'


function MyPickModal({gameType, ticketPosition, ticketStatus, ticketDate, ticketTime, gameStatus, onClick, props}) {

  const textFootball = 'Touchdown';
  const textBaseBall = 'Home runs';
  const history = useHistory();
  const prise = useFetchPrise();
  const wonText = <span style={{color: '#78FF00'}}>Congratulations! This is a qualified entry.  
                    <br/>You could win ${prise} amount at the end of the month!
                  </span>;
  const lostText = <span style={{color: '#F2B1AC'}}>
                    This entry didn’t qualify for the ${prise} monthly prize.
                   </span>;
  const newGameText = <span style={{color: '#FFC500'}}>
                    This entry is open. Check back later for the results!
                   </span>;              

  const redirectFunction = () =>  {
    history.push(`/fantasygames/${gameType}`)
  }
  
  return (
    <div className={st.content_wrapper}>
      {ticketStatus === 'WON' ? 
        <img src={congratulation} alt="smile" className={st.icon}/> :
        (
          ticketStatus === 'LOST' ? 
            <img src={smile} alt="smile" className={st.icon}/> :
            <img src={smileSpecs} alt="smile" className={st.icon}/>
        )
      }
      
      <h2 className={st.title}>
        My MegaSports {gameType} Pick #{ticketPosition}
      </h2>
      
      {/* <div className={st.score_line}>
        <span className={st.text_content}>
          {gameType === 'baseball' ? `${textBaseBall} ` : `${textFootball}`}
        </span>
        <span className={st.score}>
          {ticketStatus}
        </span>
        {gameType === 'baseball' ? <img src={pitcher} alt="baseball_bit" /> 
                                 : <img src={americanBall} alt="american_football" />} 
      </div> */}

      <div className={st.date_line}>
        <span className={st.date}>
          {ticketDate}
        </span>
        <span className={st.time}>
          {ticketTime}
        </span>
      </div>

      <div className={st.text}>
        {ticketStatus === 'WON' ? wonText : (ticketStatus === 'LOST' ? lostText : newGameText)}
      </div>
      
      <ul className={st.table}>
        <li className={st.table_header}>
          <span className={st.table_header_title}>name</span>
          <span className={st.table_header_title}>
            {gameType === 'baseball' ? textBaseBall : textFootball}
          </span>
        </li>
        
        {
          gameStatus.map((player, i) => {
            // let gameAbbr = player.game_abbr.replace('+', ' @ ');
            return  (
              <li className={st.table_player} key={player.player_name}>
                <span className={st.table_player_name}>{player.player_name}</span>
                { ticketStatus === 'NEW' ? 
                  <span className={`${st.table_player_entries}`}>TBD</span> :
                  <span className={`${st.table_player_entries} ${player.game_home_runs === 0 ? st.red : st.green}`} />
                }
              </li>
            )
          })
        }
      </ul>
      <ButtonSubmit style={{marginBottom: 30}} onClick={redirectFunction}>
        { ticketStatus === 'NEW' ? 'create a new game' : 'play again!' }
      </ButtonSubmit>
    </div>
  )
}
export default MyPickModal
