import { Link } from "react-router-dom"
import st from './loginButtons.module.scss'

const LoginButtons = () => {


  return (
    <div className={st.login_bar}>
      <div className={st.link}>
        <Link to={"/signin"} >Log In</Link>
      </div>
      <div className={st.button}>
        <Link to={"/signup"} >Play Now!</Link>
      </div>
    </div>
  )
}

export default LoginButtons
