import React from 'react'
import { useState } from "react";
import ModalWindow from '../../UI/modals/modalWindow/modalWindow';
import MyPickModal from '../../UI/modals/myPickModal/myPickModal';
import DropdownButton from '../../UI/buttons/dropdownButton/dropdownButton';
import st from './tableLine.module.scss'
import useViewport from '../../../hooks/useViewport';

function TableLine({ticketNumber, ticketPosition, gameType, ticketDateLong, ticketDateShort, ticketTime, ticketStatus, gameStatus, ticketWon, props}) {

  const [showModal, setShowModal] = useState(false);
  const toggle = () => {
    setShowModal(!showModal)
  }

  const [visible, setVisible] = useState(false);
  const toggleDropdown = () => {
    setVisible(!visible)
  }

  const breakpoint = 991;
  const { width } = useViewport();


  return (
    <li className={st.ticket}>
      <div className={st.ticket_line}>
        {/* <span className={st.ticket_line_position}>{ticketPosition}</span> */}
        <span className={st.ticket_line_name}>My MegaSports {gameType} Pick # {ticketNumber + 1}</span>
        <span className={st.ticket_line_date}>{ width < breakpoint ? ticketDateShort : ticketDateLong }</span>
        <span className={st.ticket_line_time}>{ticketTime}</span>
        <span className={st.ticket_line_status}>{ticketStatus}</span>
        <button type="button" className={st.details} onClick={toggle}>
          { width < breakpoint ? 'Details' : 'Show details' }
        </button>

        <DropdownButton toggleDropdown={toggleDropdown} styles={st.dropdown_show}/>
      </div>

      {showModal &&
        <ModalWindow visible={showModal&&"true"} setVisible={setShowModal}>
          <MyPickModal  gameType={gameType} 
                        ticketPosition={ticketNumber + 1} 
                        ticketStatus={ticketStatus} 
                        ticketDate={ticketDateLong} 
                        ticketTime={ticketTime} 
                        gameStatus={gameStatus} 
                        ticketWon = {ticketWon}
                        onClick={toggle}/>
        </ModalWindow>
      }

      {visible &&
        <div className={st.dropdown_content}>
          <div className={st.dropdown_content_wrapper}>
            <div className={st.column}>
              <div className={st.title}>Date</div>
              <div className={st.value}>{ticketDateLong}</div>
            </div>
            {/* <div className={st.column}>
              <div className={st.title}>Time</div>
              <div className={st.value}>{ticketTime}</div>
            </div> */}
            {/* <div className={st.column}>
              <div className={st.title}>Status</div>
              <span className={st.value}>{ticketStatus}</span>
            </div> */}
          </div>
          
          <button type="button" className={st.details} onClick={toggle}>Show details</button>
        </div>
      }
    </li>
  )
}

export default TableLine
