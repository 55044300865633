import { useSelector } from 'react-redux';
import ButtonSubmit from '../../../UI/buttons/buttonSubmit/buttonSubmit'
import classes from './modalTicketAccept.module.scss'
import { Link, useLocation } from "react-router-dom";
 

function ModalTicketAccept({children, visible, setVisible, onClick}) {
  let available_rates;
  const baseball_rates = useSelector(state => state.currentUser&&state.currentUser.available_rates)
  const football_rates = useSelector(state => state.currentUser&&state.currentUser.available_rates_football);
  const location = useLocation();
  const gameFlag = location.pathname === "/fantasygames/football";
  available_rates = gameFlag ? football_rates : baseball_rates;
  const margin = {marginBottom: 30};

  return (
    <div className={classes.modal_ticket_accept}>
      <div className={classes.modal_icon}></div>
      <h2 className={classes.modal_title}>
        your picks are accepted
      </h2>
      {available_rates > 0 ? 
        <div className={classes.modal_text}>
          Good luck!
        </div> :
        <div className={classes.modal_text}>
          Your picks for this week are complete! Log back in daily to check your results. Weekly picks reset each Tuesday!
        </div>
      }     
      <div className={`${classes.modal_text} ${classes.daily_picks}`}>
        Weekly picks remaining: 
        <div className={classes.tickets}>
          <div className={classes.tickets_icon}></div>
          <div className={classes.tickets_amount}>{available_rates}</div>
        </div>
      </div>
      {available_rates> 0 ?  
        <ButtonSubmit style={margin} onClick={onClick}>create a new game</ButtonSubmit> :
        <span></span>
      }
      <div className={classes.modal_link}>
        <Link to={"/fantasygames/mypicks"} className={classes.link}>View all my picks</Link>
      </div>
    </div>
  )
}

export default ModalTicketAccept

