import FootballLineUpBlock from "./footballLineUpBlock/footballLineUpBlock"
import st from "./footballLineUp.module.scss"
import ButtonSubmit from "../../../UI/buttons/buttonSubmit/buttonSubmit"
import { useDispatch, useSelector } from "react-redux"
import { loadToadayFotballPlayers, clearFootballLineupPlayers, newFootballRate } from "../../../../store/reduser"
import { useState } from "react"
import ModalWindow from "../../../UI/modals/modalWindow/modalWindow"
import ModalTicketAccept from "../../../UI/modals/modalTicketAccept/modalTicketAccept"
import ErrorModal from "../../../UI/modals/errorModal/errorModal"

const FootballLineUp =  () => {
  
  const lineUpList = useSelector(state => state.footballLineUpPlayers);

  const avalieblePlayers = useSelector(state => state.todayFootballPlayers);

  const token = useSelector(state =>state.currentUser && state.currentUser&&state.currentUser.token);

  const dispatch = useDispatch();

  const [modal, setVisible] = useState(false);

  const toggle = () => { setVisible(!modal) };

  const tickets = useSelector(state =>state.currentUser && state.currentUser.available_rates_football)

  const listId = [];
  for(let pos in lineUpList) {
    lineUpList[pos].id && listId.push(lineUpList[pos].id);
  }

  const [error, setError] = useState(
    {
      visible: false,
      title: "",
      message: ""
    }
  );

  const errorVisible = () => { 
    if(error.visible) {
      setError({
        visible: false,
        title: "",
        message: ""
      })
    } else {
      setError({
        visible: true,
        title: " Oops! You've submitted these picks already.",
        message: "Please create a new combination of players for this ticket."
      })
    }
  };

  const savePicks = (rate) => {
    let storage = JSON.parse(sessionStorage.userData);
    storage.user.available_rates_football = rate;
    sessionStorage.setItem("userData",  JSON.stringify(storage));
  };

  const sendPick = (players) => {
    let body = {'amount': 0, "game_player_ids": `[${players}]`,
     "access_token": token
    };

    return fetch("https://megasports01.herokuapp.com/football/create_rate/", {
      method: 'POST',
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json"
      },
      body: JSON.stringify(body)
    }).then( response => {
        return response.json()
      } ).then((resp => {
      if ( resp.error === "you already have 5 rates"){
        dispatch(newFootballRate(0));
        toggle();
      } else if ( resp.error ===  "you have a rate on these players") {
        errorVisible();
      } else {
        dispatch(newFootballRate(resp.available_rates));
        savePicks(resp.available_rates);
        setVisible(true);
      }
    }))
  }

  const submitHandler = () => {
    sendPick(listId.toString());
    for(let list of Object.keys(avalieblePlayers)) {
      let item = avalieblePlayers[list].map(player => ({...player, checked: false}));
      avalieblePlayers[list] = item;
     };
     dispatch(loadToadayFotballPlayers(avalieblePlayers));
     dispatch(clearFootballLineupPlayers());
  }
  return (
    <div className={st.lineup}>
      <div className={st.lineup_title}>
        <h2 className={st.lineup_title_text}>
          Your Lineup
        </h2>
        <div className={st.lineup_title_picked}>
          {listId.length > 0 ? listId.length : "0"}/3
        </div>
      </div>
      <div className={st.lineup_blocks_wrapper}>
        <FootballLineUpBlock position="quarterback" pos="QB"/>
        <FootballLineUpBlock position="wide receiver or tight end" pos="WR_TE"/>
        <FootballLineUpBlock position="running back" pos="RB"/>
      </div>
      <ButtonSubmit onClick={submitHandler} disabled={(listId.length !== 3) || (tickets < 1)}>lock picks</ButtonSubmit>
      {modal&&
        <ModalWindow visible={modal&&"true"} setVisible={setVisible}>
          <ModalTicketAccept onClick={toggle}/>
        </ModalWindow>
      }
      <ModalWindow visible={error.visible&&"true"} setVisible={errorVisible}>
        <ErrorModal  title={error.title} message={error.message} />
      </ModalWindow>
    </div>
  )
}

export default FootballLineUp