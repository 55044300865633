import { useHistory } from 'react-router-dom'
import st from './buttonClose.module.scss'

function ButtonClose(props) {
  const history = useHistory();
  return (
    <div className={st.button_close} onClick={() => {!props.blocking ? props.setVisible(false) : history.push('/fantasygames/mypicks')}}>
      <div className={st.button_wrapper}></div>
    </div>
  )
}

export default ButtonClose
