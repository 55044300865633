import st from './customInput.module.scss'
import React from  "react"

const CustomInput = React.forwardRef(({onChange, placeholderText, inputType, inputName, styleInput, props, ...attrs}, ref) => {
return   <input 
    style={styleInput} 
    className={st.customInput} 
    type={inputType} 
    placeholder={placeholderText} 
    name={inputName}
    onChange={onChange}
    {...attrs}
    ref={ref}
  />
}) 
    

export default CustomInput

