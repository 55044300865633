import ButtonClose from '../../../UI/buttons/buttonClose/buttonClose'
import classes from './modalWindow.module.scss';
import React from "react";
//import { useHistory } from 'react-router-dom';


function ModalWindow({children, visible, setVisible, blocking}) {

  const rootClasses = [classes.modal_window]
  //const history = useHistory();

  if (visible) {
    rootClasses.push(classes.active)
  } 

  return (
    <div className={rootClasses.join(' ')} onClick={!blocking ? () => setVisible(false) : null}>
      <div className={classes.modal_window_content} onClick={(e) => e.stopPropagation()}>
        {!blocking && <ButtonClose setVisible={setVisible} blocking={blocking}></ButtonClose>}
        {children}
      </div>
    </div>
  )
}

export default ModalWindow
