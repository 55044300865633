import st from './header.module.scss'
import HeaderLogo from '../../UI/buttons/logo/headerLogo'

const Header = (props) => {

  return (
    <div className={st.header}>
      <HeaderLogo />
      {props.navBar}
      {props.rightBlock}
    </div>
  )
}

export default Header