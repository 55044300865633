import React from 'react'
import styles from './loadingSpinner.module.scss'

function LoadingSpinner() {
  
  return (
    <div className={styles.loader_wrapper}>
      <div className={styles.loader}></div>
    </div>
  )
};
export default LoadingSpinner
