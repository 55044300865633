import { useEffect, useState } from "react";

const useFetchPrise = () => {

  const [prise, setPrise] = useState(0);

  useEffect(() => {
    fetch( "https://megasports01.herokuapp.com/project/win_amount", 
    {
      method: 'GET', 
      headers: {
        "Accept": "application/json",
      },
    }).then( response => {
      return response.json()
    }).then (resp => {
      const data = resp.win_amount;
      setPrise(data)
    })
  }, [])

  return prise
}

export default useFetchPrise
