
const defaultState = { 
  currentUser: null,
  userRates: null,
  todayPlayers: [],
  lineUpPlayers: [],
  gameList: [],
  todayFootballPlayers: {"RB": [], "QB": [], "WR_TE": []},
  footballLineUpPlayers: {"RB": {}, "QB": {}, "WR_TE": {}}
}

const ADD_CURRENT_USER = 'ADD_CURRENT_USER';
const LOAD_TODAY_PLAYERS = 'LOAD_TODAY_PALYERS';
const ADD_LINEUP_PLAYER = 'ADD_LINEUP_PLAYER';
const CHANGE_PLAYER_STATUS = 'CHANGE_PLAYER_STATUS';
const REMOVE_LINEUP_PLAYER = 'REMOVE_LINEUP_PLAYER';
const CLEAR_LINEUP_PLAYERS = 'CLEAR_LINEUP_PLAYERS';
const LOAD_GAMES = 'LOAD_GAMES';
const NEW_RATE = 'NEW_RATE';
const LOAD_TODAY_FOOTBALL_PLAYERS = 'LOAD_TODAY_FOOTBALL_PALYERS';
const ADD_FOOTBALL_LINEUP_PLAYER = 'ADD_FOOTBALL_LINEUP_PLAYER';
const CHANGE_FOOTBALL_PLAYER_STATUS = 'CHANGE_FOOTBALL_PLAYER_STATUS';
const REMOVE_LINEUP_FOOTBALL_PLAYER = 'REMOVE_LINEUP_FOOTBALL_PLAYER';
const CLEAR_FOOTBALL_LINEUP_PLAYERS = 'CLEAR_FOOTBALL_LINEUP_PLAYERS';
const NEW_FOOTBALL_RATE = 'NEW_FOOTBALL_RATE';
const SET_USER_RATES = 'SET_USER_RATES'


export const reduser = (state = defaultState, action) => {
  switch (action.type) {

    case ADD_CURRENT_USER:
      return {...state, currentUser: action.payload}
    case LOAD_TODAY_PLAYERS:
      return {...state, todayPlayers: action.payload}
    case ADD_LINEUP_PLAYER:
      return {...state, lineUpPlayers: state.lineUpPlayers.concat(action.payload)}
    case CHANGE_PLAYER_STATUS: 
      return {...state, todayPlayers: state.todayPlayers.map((player) =>{
        if(action.payload.name === player.name ) { player = action.payload }
        return player
      }) }
    case REMOVE_LINEUP_PLAYER: 
      return {...state, lineUpPlayers: state.lineUpPlayers
        .filter(player => player.name !== action.payload.name)
      }
    case CLEAR_LINEUP_PLAYERS:
      return {...state, lineUpPlayers: [], 
        todayPlayers: state.todayPlayers.map(player => ({...player, checked: false})) }
    case LOAD_GAMES:
      return {...state, gameList: action.payload}
    case NEW_RATE: 
      return {...state, currentUser: {...state.currentUser, available_rates: action.payload}}
    case LOAD_TODAY_FOOTBALL_PLAYERS:
      return {...state, todayFootballPlayers: action.payload}
    case ADD_FOOTBALL_LINEUP_PLAYER:
      return {...state, footballLineUpPlayers: Object.assign({}, state.footballLineUpPlayers, action.payload)}
    case CHANGE_FOOTBALL_PLAYER_STATUS: 
      let clone = {...state.todayFootballPlayers}
      for(let list of Object.keys(clone)) {
        let item = clone[list].map((player) => {
          if(action.payload.name === player.name ) {
            return  player = action.payload } else {
              return player
            }
        });
        clone[list] = item;
      };
      return {...state, todayFootballPlayers: clone}
    case REMOVE_LINEUP_FOOTBALL_PLAYER:
      return {...state, footballLineUpPlayers: Object.assign({}, state.footballLineUpPlayers, action.payload)}
    case CLEAR_FOOTBALL_LINEUP_PLAYERS: 
      return {...state, footballLineUpPlayers: {"RB": {}, "QB": {}, "WR_TE": {}} }
    case NEW_FOOTBALL_RATE: 
      return {...state, currentUser: {...state.currentUser, available_rates_football: action.payload}}
    case SET_USER_RATES:
      return {...state, userRates: action.payload}

    default: return state
  }
}

export const addCurrntUser = (payload) => ({type: ADD_CURRENT_USER, payload});
export const loadToadayPlayers = (payload) => ({type: LOAD_TODAY_PLAYERS, payload});
export const addLineupPlayer = (payload) => ({type: ADD_LINEUP_PLAYER, payload});
export const changaePlayerStatus = (payload) => ({type: CHANGE_PLAYER_STATUS, payload});
export const removelineupPlayer = (payload) => ({type: REMOVE_LINEUP_PLAYER, payload});
export const clearLineupPlayers = () => ({type: CLEAR_LINEUP_PLAYERS});
export const loadGames = (payload) => ({type: LOAD_GAMES, payload});
export const newRate = (payload) => ({type: NEW_RATE, payload});
export const loadToadayFotballPlayers = (payload) => ({type: LOAD_TODAY_FOOTBALL_PLAYERS, payload});
export const addFootballLineupPlayer = (payload) => ({type: ADD_FOOTBALL_LINEUP_PLAYER, payload});
export const changaeFootballPlayerStatus = (payload) => ({type: CHANGE_FOOTBALL_PLAYER_STATUS, payload});
export const removeLineupFootballPlayer = (payload) => ({type: REMOVE_LINEUP_FOOTBALL_PLAYER, payload});
export const clearFootballLineupPlayers = () => ({type: CLEAR_FOOTBALL_LINEUP_PLAYERS});
export const newFootballRate = (payload) => ({type: NEW_FOOTBALL_RATE, payload});
export const setUserRates = (payload) => ({type: SET_USER_RATES, payload})