import React from 'react'
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import st from './myPicks.module.scss'
import { NavLink } from 'react-router-dom'
import useSortableData from '../../hooks/useSortData';
import TableLine from './tableLine/tableLine'
import LoadingSpinner from '../UI/loadingSpinner/loadingSpinner'
import CustomRadioButton from '../UI/customRadioButton/customRadioButton'
import CustomSelect from '../UI/customSelect/customSelect' 
import PaginationBlock from './paginationBlock/paginationBlock'
import { HelperSpinner } from '../../helpers/HelperSpinner';


function MyPicks(props) {
  
  let gamesList = HelperSpinner(7);
  const [list, setList] = useState(gamesList)
  const func = (inputData) => {
    setList(inputData)
  }

  const [pageParam, setPageParam] = useState({})
  const pageSetter = (params) => {
    setPageParam(params)
  } 

  const userWin = useSelector(state => state.userRates)

  // fetch 
  const token = useSelector(state => state.currentUser && state.currentUser.token);

  const [bodyObject, setBodyObject ] = useState({
    "time_period": 'month',
    "player_name": 'emty',
    "status": 'emty',
    "type_game": 'football',
    "access_token": token,
    "url": "https://megasports01.herokuapp.com/project/user_rates/"
  })

  const setParameters = (field, value) => {
    bodyObject[field] !== value ?
     setBodyObject({...bodyObject, [field]: value}) : console.log('error data')
  }
  
  // sorting 
  const {players, requestSort} = useSortableData(list);

  useEffect(() => {
    fetch(bodyObject.url, 
      {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
      },
      body: JSON.stringify(bodyObject)
      }).then( response => {
        return response.json()
      }).then((resp) => {
        if(!resp.error) {
          const list = resp.results;
          const previousPage = resp.previous;
          const nextPage = resp.next;
          let currentPage = nextPage ? (nextPage && parseInt(nextPage.slice(-1)) - 1) : (previousPage && parseInt(previousPage.slice(-1)) + 1);
          const withNumbers = list && list.map((game, i) => {
            game.pos = 7*(parseInt(currentPage) - 1) + i +1;
            return game;
          });
          func(withNumbers);
          gamesList = withNumbers;
          pageSetter({
            rateCount: resp.count,
            nextPage: resp.next,
            previousPage: resp.previous
          })
        } else {
          fetch("https://megasports01.herokuapp.com/project/user_rates/", 
            {
              method: 'POST',
              mode: "cors",
              headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json"
            },
            body: JSON.stringify(bodyObject)
            }).then( response => {
              return response.json()
            }).then((resp) => {
              const list = resp.results;
              const previousPage = resp.previous;
              const nextPage = resp.next;
              let currentPage = nextPage ? (nextPage && parseInt(nextPage.slice(-1)) - 1) : (previousPage && parseInt(previousPage.slice(-1)) + 1);
              const withNumbers = list && list.map((game, i) => {
                game.pos = 7*(parseInt(currentPage) - 1) + i +1;
                return game;
              });
              func(withNumbers);
              gamesList = withNumbers;
              pageSetter({
              rateCount: resp.count,
              nextPage: resp.next,
              previousPage: resp.previous
            })
          })
        }
      })
  }, [bodyObject])
  

  let itemsCurrentPage = players;


  const postData = itemsCurrentPage.map((ticket, index) => {
    let currentDate = new Date(ticket.created_at);

    let optionsLongDate = { month: 'long', day: '2-digit', year: 'numeric' };
    let optionsShortDate = { month: 'short', day: '2-digit', year: '2-digit' };
    
    let pickDateLong = currentDate.toLocaleDateString('en-US', optionsLongDate).replace(/ /g, ' ');
    let pickDateShort = currentDate.toLocaleDateString('en-US', optionsShortDate).replace(/ /g, ' ');
    
    const timeOptions = { hour: 'numeric', minute: 'numeric', hour12: true };
    let pickTime = currentDate.toLocaleTimeString('en-US', timeOptions);

    let gameStatus = ticket.rates_player;

    const wonGameCount = gameStatus && gameStatus.reduce( (tally, gameResult) => {
      gameResult.status === 'W' ? tally = tally + 1 : tally = tally + 0;
      return tally;
    } , 0)

    let totalGameAmount;
    if (ticket.type === 'baseball') {
      totalGameAmount = 4;
    } else if (ticket.type === 'football') {
      totalGameAmount = 3;
    }

    // let ticketStatusWon = <span style={{color: '#008C4E'}}>{`${wonGameCount}/${totalGameAmount}`}</span>
    // let ticketStatusLost = <span style={{color: '#D4335B'}}>{`${wonGameCount}/${totalGameAmount}`}</span>
    // let ticketStatusNew = <span style={{color: 'blue'}}>NEW</span>
    // let value;
    // if(ticket.status === 'WON') {
    //   value = ticketStatusWon
    // } else if(ticket.status === 'LOST'){
    //   value = ticketStatusLost
    // } else {
    //   value = ticketStatusNew
    // }

    return ticket.id ? 
      <TableLine 
        key={ticket.id} 
        ticketPosition = {ticket.pos}
        ticketNumber = {ticket.number}
        gameType = {ticket.type}
        ticketDateLong = {pickDateLong}
        ticketDateShort = {pickDateShort}
        ticketTime = {pickTime}
        // ticketStatus = {value}
        gameStatus = {gameStatus}
        ticketStatus = {ticket.status}
      /> : 
      <LoadingSpinner key={Math.random()}/>
  });


  // filter using text input   
  // const [name, setName] = useState('');
  // const filterSearch = useCallback((e) => {
  //     const keyword = e.target.value;
  //     if (gamesList && keyword !== '') {
  //       const results = gamesList.filter((ticket) => {
  //         let filteredTicket = ticket.rates_player.filter((player) => {
  //           let consistPlayerName = player.player_name.toLowerCase().startsWith(keyword.toLowerCase());
  //           let consistGameName = player.game_abbr.toLowerCase().startsWith(keyword.toLowerCase());
  //           if (consistPlayerName || consistGameName) {
  //             return true
  //           } else return false
  //         });
  //       return filteredTicket.length !== 0 ? ticket : 0
  //       });
  //       func(results);
  //     } else {
  //       func(gamesList);
  //     }
  //   setName(keyword);
  // }, [])
  
  let inProgress = false;

  const filterSearch = useCallback((e) => {
    if (inProgress) return;
    inProgress = true;
    setTimeout(() => {
      const keyword = e.target.value;
      // setParameters('type_game', keyword);
      keyword !== '' ? setParameters('player_name', keyword) : setBodyObject({...bodyObject, 'player_name': 'emty', "url": "https://megasports01.herokuapp.com/project/user_rates/"});
      inProgress = false;
    }, 2000);
  }, [])

  const [showFilters, setShowFilters] = useState(false);
  const toggle = () => {
    setShowFilters(!showFilters)
  }


//const periodForSelect = [
//  {
//    name: "This month",
//    value: "month"
//  },
//  {
//    name: "This year",
//    value: "year"
//  }
//]

const gamesStatusForSelect = [
  {
    name: "ALL",
    value: "emty"
  },
  {
    name: "WON",
    value: "WON"
  },
  {
    name: "LOST",
    value: "LOST"
  }
]
  return (
    <div className={`${st.my_picks_page} ${props.embedded ? st.embedded : st.standalone}`} >
      <div className={st.my_picks_page_title}>
        <h2 className={st.my_picks_page_title_text}>
          My Picks
        </h2>
        <div className={st.my_picks_page_title_picked}>
          Qualified Entries: {userWin}
        </div>
      </div>

      <div className={st.content_wrapper}>
      {/*<div className={st.links}>
          <div className={st.link}>
            <NavLink to={"/fantasygames/leaderboard"} exact={true} activeClassName={st.selected}>Weekly leaderboard</NavLink>
          </div>
          <div className={st.link}>
            <NavLink to={"/fantasygames/mypicks"} className={st.mypicks_linck}>My picks</NavLink>
          </div>
        </div> */}

      {/*<h2 className={st.page_title}>My picks</h2> */}
        {/*<div className={st.tickets}> */}
          {/*<span className={st.tickets_title}>Qualified Entries:</span> */}
          {/* <span className={st.tickets_icon}></span> */}
      {/*<span className={st.tickets_amount}>{userWin}</span> */}
        {/*</div> */}

        <div className={st.action_form}>
          <div className={st.action_form_line}>
            <label className={st.search_label}>
              <input
                type="search"
                onChange={filterSearch}
                placeholder="Filter"
                placeholder="Search for players" 
                className={st.search}
              />
            </label>

            <div className={st.radio_buttons}>
              <CustomRadioButton textLabel="All" groupName="games" value="ALL" checkedValue={bodyObject['status'] === "emty"} onClick={() => {
                setParameters('status', "emty")
              }}/>
              <CustomRadioButton textLabel="Won" groupName="games" value="WON" checkedValue={bodyObject['status'] === "WON"}  onClick={() => {
                setParameters('status', "WON")
              }}/>
              <CustomRadioButton textLabel="Lost" groupName="games" value="LOST" checkedValue={bodyObject['status'] === "LOST"}  onClick={() => {
                setParameters('status', "LOST")
              }} />
            </div>

      {/*<CustomSelect 
              placeholderText="This month"
              styleSelect={st.filters_select_period}
              queryKey='time_period'
              selectHandler={setParameters}
              inputName="period"
              options={periodForSelect}
              activeValue={bodyObject['time_period']}
              inputValue={periodForSelect.find(el=>el.value === bodyObject['time_period']).name}
            />

            <label className={st.filter}>
              <input type="checkbox" onClick={toggle} className={st.filter_input}></input>
              <span className={st.filter_block}></span>
            </label> */}
            
          </div>

          {showFilters &&
            <div visible={showFilters&&"true"} onClick={setShowFilters} className={st.filters_select}>
              <CustomSelect 
                placeholderText="All"
                styleSelect={st.filters_select_games}
                queryKey='status'
                selectHandler={setParameters}
                inputName="games_status"
                options={gamesStatusForSelect}
                activeValue={bodyObject['status']}
                inputValue={gamesStatusForSelect.find(el=>el.value === bodyObject['status']).name}
              />

              {/*<CustomSelect 
              placeholderText="This month"
              styleSelect={st.filters_select_period}
              queryKey='time_period'
              selectHandler={setParameters}
              inputName="mobile_period"
              options={periodForSelect}
              activeValue={bodyObject['time_period']}
              inputValue={periodForSelect.find(el=>el.value === bodyObject['time_period']).name}
            /> */}
            </div> 
          }
          
        </div>

        <div className={st.games_list}>
          <div className={st.games_list_header}>
            <div className={st.games_list_header_wrapper}>
              {/* <span className={st.header_title}>Pos</span> */}
              <span className={st.header_title}>MY entries</span>
            
              <label className={`${st.sort_buttons_wrapper} ${st.header_title}`}>
                <span className={st.sort_buttons_wrapper_text}>Date</span>
                <input
                  name="sortPlayers"
                  type="radio"
                  value="position"
                  onClick={() => requestSort('created_at')}
                  className={st.sort_buttons_input} />
              </label>

              <label className={`${st.sort_buttons_wrapper} ${st.header_title}`}>
                <span className={st.sort_buttons_wrapper_text}>Time</span>
                <input
                  name="sortPlayers"
                  type="radio"
                  value="position"
                  onClick={() => requestSort('created_at')}
                  className={st.sort_buttons_input} />
              </label>

              <label className={`${st.sort_buttons_wrapper} ${st.header_title}`}>
                <span className={st.sort_buttons_wrapper_text}>status</span>
                <input
                  name="sortPlayers"
                  type="radio"
                  value="position"
                  onClick={() => requestSort('status')}
                  className={st.sort_buttons_input} />
              </label>

              <span className={st.header_title}></span>
            </div>
            
          </div>

          <ul className={st.table}>
            {postData}
          </ul>

        </div>
        {pageParam.rateCount > 7 && <PaginationBlock 
          object={pageParam} 
          setParameters={setParameters}
        />}
        </div>
      </div>
  )
}

export default MyPicks
