import { useDispatch } from 'react-redux';
import { changaePlayerStatus, removelineupPlayer, changaeFootballPlayerStatus, removeLineupFootballPlayer } from '../../../../store/reduser';
import st from './buttonDelete.module.scss'
import { useLocation } from 'react-router-dom';

function ButtonDelete(props) {
  const player = props.player;

  const location = useLocation();

  const dispatch = useDispatch();

  const remove = () => {
    if(location.pathname === "/fantasygames/football") {
      dispatch(removeLineupFootballPlayer({[props.pos]: {}}));
      player.checked = !player.checked;
      dispatch(changaeFootballPlayerStatus(player))
    } else {
      dispatch(removelineupPlayer(player));
      player.checked = !player.checked;
      dispatch(changaePlayerStatus(player))
    }
  }
  return (
    <button className={st.button_delete} onClick={remove}></button>
  )
}

export default ButtonDelete