import st from "../fantasyGames.module.scss"
import WelcomeWindow from "../welcomeWindow/welcomeWindow"
import NextGameDate from "../nextGameDate/nextGameDate"
import FootballLineUp from "./footballLineUp/footballLineUp"
import MyPicks from "../../myPicks/myPicks"

const Football = () => {

  return (
    <div className={st.fantasy_games_wrapper}>
      <div className={`${st.blocks_wrapper} ${st.block_first}`}>
        <WelcomeWindow className={st.welcome_window} />
        <NextGameDate className={st.next_game_date}/>
      </div>
        <FootballLineUp />
        <MyPicks embedded={true} />
    </div>
  )
}

export default Football
