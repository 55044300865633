import React from 'react';
import Header from './header/header';
import st from './fantasyGames.module.scss'
import NavigationBar from "../fantasyGames/header/navigationBar/navigationBar"
import RightBlock from "../fantasyGames/header/rightBlock/rightBlock";
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from 'react-router-dom';
import { addCurrntUser } from '../../store/reduser';
// import Baseball from './baseball/baseball';
import { Redirect, Route, Switch } from 'react-router-dom/cjs/react-router-dom.min';
import Football from './football/football';
// import WeeklyLeaderboard from '../weeklyLeaderboard/weeklyLeaderboard';
import MyPicks from '../myPicks/myPicks';


const  FantasyGames = () => {
  
  const navBar = <NavigationBar />
  const rightBlock = <RightBlock />

  const history = useHistory();

  const user = useSelector(state => state.currentUser);

  const dispatch = useDispatch();
  

  if (!user) {
    const sessionUser = sessionStorage.userData&&JSON.parse( sessionStorage.userData );
    const today = (new Date()).getDate();
    if(!sessionUser) {
      //dispatch(addCurrntUser(null));
      history.push("/presentation");
    } else if(today !== sessionUser.date) {
      history.push("/signin");
    } else {
      dispatch(addCurrntUser(sessionUser.user));
    }
  }

  // fetch("http://baseball2021-dev.herokuapp.com/weelky_leadboard/", 
  // {
  //   method: 'GET',
  //   headers: {
  //     "Accept": "application/json",
  //   },
  // }).then( response => {
  //   return response.json()
  // }).then (resp => {
  //   return resp
  // })
  
  return (
      <div className={st.fantasy_wrapper}>
        <div className={st.fantasy_games} >
          <Header navBar={navBar} rightBlock={rightBlock} className={st.header}/>

          <Switch>
            <Route exact path="/fantasygames">
              <Redirect to="/fantasygames/football" />
            </Route>
            {/* <Route path="/fantasygames/baseball" render={()=>{return <Baseball/>}} /> */}
            <Route path="/fantasygames/football" exact component={Football} />
            {/* <Route path="/fantasygames/leaderboard" component={WeeklyLeaderboard} /> */}
            <Route path="/fantasygames/mypicks" component={MyPicks} />
            <Route path="*" >
                <Redirect to="/fantasygames/football" />
              </Route>
          </Switch>
            
        </div>
      </div>
  )
}

export default  FantasyGames
