import React from 'react'
import st from './customRadioButton.module.scss'

function CustomRadioButton({textLabel, groupName, value, checkedValue, onClick, props}) {
  return (
    <label className={st.radio_buttons_label}>
      <input 
        type="radio" 
        name={groupName} 
        value={value} 
        defaultChecked={checkedValue} 
        onClick={()=>{onClick(value)}}
      />
      <span>{textLabel}</span>
    </label>
  )
}

export default CustomRadioButton
