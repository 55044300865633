import React, {useState} from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useForm } from 'react-hook-form';
import st from './passwordCreate.module.scss';
import CustomInput from '../../UI/customInput/customInput';
import ButtonSubmit from '../../UI/buttons/buttonSubmit/buttonSubmit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Logo from '../../UI/buttons/logo/logo'

const PasswordCreate = () => {

  const location = useLocation();

  const history = useHistory();

  const token = location.search && location.search.slice(7);

  const schema = yup.object().shape({
    password: yup.string()
      .required()
      .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, "Password may contain one capital later and one number")
      .min(8, 'Min 8 characters'),
    confirmPassword: yup.string()
      .required()
      .test('passwords-match', 'Passwords dont match', function(value){
      return this.parent.password === value
    })
  });

  const posRelative = {
    position: "relative"
  }

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false)

  const toggleClass = () => {
    setPasswordShown(!passwordShown);
  }

  const toggleClassConfirm = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  }

  const showPassword = (key) => {
    setPasswordShown((prevState) => {
      return {...prevState, [key]: !prevState[key]} 
    })
    toggleClass();
  }

  const showConfirmPassword = (key) => {
    setConfirmPasswordShown((prevState) => {
      return {...prevState, [key]: !prevState[key]} 
    })
    toggleClassConfirm();
  }
  
  const {register, handleSubmit, watch, 
    formState:{ 
      errors
   }} = useForm({resolver: yupResolver(schema)});

  const watchPassword = watch("password");

  const onSubmit = () => {
    //history.push("/");
    const body = {
      "password": watchPassword, 
      "access_token": token
    }
      fetch("https://megasports01.herokuapp.com/project/password_create/", {
        method: 'POST',
        mode: "cors",
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json"
        },
        body: JSON.stringify(body)
      }).then( response => {
        return response.json()
      }).then((resp) => {
        if(resp == 'password updated') {
          history.push('/signin')
        }
      })
  }

  const passwordInput = register('password');
  const confirmPasswordInput = register('confirmPassword');

  return (
    <div className={st.login_wrapper}>
        <div className={st.login_page}>
          <Link to={"/presentation"} className={st.logo_link}>
          	<Logo />
          </Link>
          <h2 className={st.page_title}>CREATE A NEW PASSWORD</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={st.password_create}>
            <label className={`${st.label_input} ${st.password}`}>
              <div className={st.subTitleWrapper}>
                <span>New Password</span>  
                {errors.password&&<span className={st.error}>{errors.password.message}</span>}
              </div>
              <CustomInput
                styleInput={posRelative}
                inputType={passwordShown ? "text" : "password"} 
                inputName={passwordInput.name}  
                placeholderText="Min 8 characters" 
                {...passwordInput}
              />
              <button type="button" className={passwordShown ? `${st.eye}` : `${st.eye_off}`} onClick={showPassword}></button>
            </label>
            <label className={`${st.label_input} ${st.password}`}>
              <div className={st.subTitleWrapper}>
                <span>Confirm Password</span>  
                {errors.confirmPassword&&<span className={st.error}>{errors.confirmPassword.message}</span>}
              </div>
              <CustomInput
                styleInput={posRelative}
                inputType={confirmPasswordShown ? "text" : "password"} 
                inputName={confirmPasswordInput.name}  
                placeholderText="Repeat your password" 
                {...confirmPasswordInput}
              />
              <button type="button" className={confirmPasswordShown ? `${st.eye}` : `${st.eye_off}`} onClick={showConfirmPassword}></button>
            </label>
            <ButtonSubmit type="submit">
              UPDATE PASSWORD
            </ButtonSubmit>
          
            <div className={st.bottom_question}>
              <span>Don't have an account? </span>
              <Link to={"/signUp"}>Sign up</Link>
            </div>
          </form>
        </div>
    </div>
  )
}

export default PasswordCreate
