import st from './welcomeWindow.module.scss'
import React from "react";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useFetchPrise from '../../../hooks/useFetchPrise';

function WelcomeWindow(props) {
  
  const userName = useSelector(state => state.currentUser&&state.currentUser.user_name);

  const prise = useFetchPrise();

  const userRates = useSelector(state => state.userRates);

  const location = useLocation();
  
  const gameFlag = location.pathname === "/fantasygames/football";

  return (
    <div className={st.welcome_window}>
      <div className={st.welcome_left_side}>
        <h2 className={st.welcome_greeting}>
          welcome back, {userName}!
        </h2>
        {
          gameFlag ? 
          <p className={st.welcome_text}>There are up to 30 players from each position from which to choose! 
            Pick three players, one from each position, who you believe will 
            score a touchdown in this week's games! If all three of your players 
            score a touchdown, you'll automatically be entered for a chance to win ${prise}.</p> :
          <p className={st.welcome_text}>
            Hello {userName}! MegaSports Baseball is simple. Just pick 4 players you think will 
            hit a home run today for your chance to win.  If all of your players hit a homerun, 
            you’ll automatically be entered for a chance to win ${prise}.
          </p>
        }
      </div>
      <div className={st.welcome_right_side}>
        <p className={st.welcome_right_side_text}>
          My MegaPool Qualified Entries
        </p>
        <p className={st.welcome_gain}>
          {userRates}
        </p>
      </div>
    </div>
  )
}

export default WelcomeWindow
