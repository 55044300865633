import React, {useState } from "react";
import st from './passwordRecover.module.scss';
import { Link } from "react-router-dom";
import { useForm } from 'react-hook-form';
import CustomInput from '../../UI/customInput/customInput';
import ButtonSubmit from '../../UI/buttons/buttonSubmit/buttonSubmit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import Logo from '../../UI/buttons/logo/logo'

const PasswordRecover = () => {

  // const location = useLocation();

  const schema = yup.object().shape({
    email: yup.string()
      .required('Email is required field')
      .email('Email is invalid')
  });

  const [formStatus, setFormStatus] = useState({
    messageText: null,
    isError: false
  })
 
  const {register, handleSubmit, 
    formState:{ 
      errors
   }} = useForm({resolver: yupResolver(schema)});

  const onSubmit = (data) => {
    if(!formStatus.messageText) {
      const body = {"email": data.email}
      fetch("https://megasports01.herokuapp.com/project/password_recover/", {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json"
        },
        body: JSON.stringify(body)
      }).then( response => {
        if(response.ok) {
          setFormStatus({...formStatus,
            'messageText': 'We sent you an email with instructions to reset your password' 
          })
        } else {
          setFormStatus({...formStatus,
            'messageText': 'This email is not registered',
            "isError": true
          })
        }
      })
    } else {
      setFormStatus({
        messageText: null,
        isError: false
      })
    }
  };

  const emailInput = register('email');

  return (
    <div className={st.login_wrapper}>
        <div className={st.login_page}>
          <Link to={"/presentation"} className={st.logo_link}>
          	<Logo />
          </Link>
          <h2 className={st.page_title}>recover your password</h2>
          <form onSubmit={handleSubmit(onSubmit)} className={st.password_recover}>
            {!formStatus.messageText ?
              <label className={st.label_input}>
                <div className={st.subTitleWrapper}>
                  <span>Email address</span>
                  {errors.email&&<span className={st.error}>{errors.email.message}</span>}
                </div>
                <CustomInput 
                  inputType="text" 
                  inputName={emailInput.name}
                  onChange={emailInput.onChange} 
                  {...emailInput}
                  placeholderText="example@gmail.com"
                />
              </label> :
              <p className={st.sendedMessage} style={formStatus.isError ? {color: "red"} : {color: "#52595E"}}>{formStatus.messageText}</p>
            }
            <ButtonSubmit type="submit" disabled={errors.email}>
              {formStatus.messageText ? "RESEND A RECOVERY LINK" : "Get a recovery link"}
            </ButtonSubmit>
            <div className={st.bottom_question}>
              <span>Don't have an account? </span>
              <Link to={"/signUp"}>Sign up</Link>
            </div>
          </form>
        </div>
    </div>
  )
}

export default PasswordRecover
